import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/modules/shared/services/http.service';
import { PopupService } from 'src/modules/shared/services/popup.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent  implements OnInit {

  isLoading = false;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private popupService: PopupService,
  ) { }

  async sendInstruction(email: string) {
    try {
      this.isLoading = true;
      await this.httpService.httpPostRequest(
        '/auth/employee/forgot-password',
        {email}
      );
      this.router.navigateByUrl('/send-instruction');
    } catch (err: any) {
      console.log(err);
      this.popupService.showErrorModal(err.error.message || err.error);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnInit() {}

}



