import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackgroundCheckStatus } from '../authentication/interfaces/user.interface';

@Component({
  selector: 'app-background-check-list-item',
  templateUrl: './background-check-list-item.component.html',
  styleUrls: ['./background-check-list-item.component.scss'],
})
export class BackgroundCheckListItemComponent implements OnInit {
  @Input()
  backgroundCheckStatus: BackgroundCheckStatus =
    BackgroundCheckStatus.NOT_REQUIRED;
  constructor(private router: Router) {}

  ngOnInit() {}

  goToBackgroundCheckScreen() {
    this.router.navigateByUrl('/credentials/background-check');
  }
}
