import { Component, OnInit, Input } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'credential-instruction-modal',
  templateUrl: './credential-instruction-modal.component.html',
  styleUrls: ['./credential-instruction-modal.component.scss'],
})
export class CredentialInstructionModalComponent implements OnInit {
  @Input() credential!: ICredential;
  credentialName = '';

  constructor(
    private platform: Platform,
    private imagesService: ImagesService,
    private modalController: ModalController,
    private router: Router
  ) {}

  ngOnInit() {
    this.credentialName = this.credential.name.replace('/', ' / ');
  }

  goBack(): void {
    this.modalController.dismiss();
  }

  async uploadNow(): Promise<void> {
    await this.modalController.dismiss();
    this.router.navigateByUrl('/credentials/upload', {
      state: { 
        justGoBack: true, 
        credential: this.credential,
      },
    });
  }
}
