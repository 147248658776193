<div class="login-container">
  <div>
    <ion-card>
      <div class="company-logo"></div>

      <ion-card-header>
        <ion-card-title>Password recovery</ion-card-title>
      </ion-card-header>

      <ion-card-content class="card-content">
        <h1 class="title">Please enter the e-mail associated with your account and we'll send you instruction to set a new password</h1>
        <form class="login-form " >
          <div class="form-container">
            <div class="input-item input-item-with-icon">
              <ion-label>E-mail</ion-label>
              <ion-input #email type="email" class="app-input" placeholder="Enter Email"></ion-input>
            </div>

            <div class="input-item action-container">
              <ion-button type="submit" (click)="sendInstruction(email.value + '')">
                Send instruction
                <ion-spinner *ngIf="isLoading" name="lines-sharp-small" ></ion-spinner>
              </ion-button>
            </div>

          </div>
        </form>
      </ion-card-content>
    </ion-card>
    <div class="footer">
      <div class="powered-by">
        Powered by
      </div>
      <img src="../../../assets/images/logo.png" />
    </div>

  </div>
</div>