import { Injectable, inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { LocalStorageService } from '../../modules/shared/services/local-storage.service';


@Injectable()
export class AuthGuardService {

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const user = await this.localStorageService.getLoggedInUserInfo();
        if (!user) {
            this.router.navigateByUrl('/login');
            return false;
        }
        if (!user.isTempPasswordReset && route.url[0].path !== 'reset-password') {
            this.router.navigateByUrl('/reset-password');
            return false;
        }
        return true;
    }
}

export const canActivateWithAuth: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const canActivate: boolean = await inject(AuthGuardService).canActivate(route);
    return canActivate;
}
