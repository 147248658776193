<div *ngIf="!userData" class="staffer">
  <div class="ion-text-center child"
    [ngClass]="{'quizDetail' : specificClass == 'quizModal', 'detail' : specificClass != 'quizModal'}">
    
    <div class="icon-cancel">
      <ion-icon class="close-icon" name="close-outline" (click)="closeMenu()"></ion-icon>
    </div>
    
    <div class="img-container">
      <img *ngIf="imgURL" src="{{imgURL}}">
    </div>

    <div class="heading">
      <h1 [ngClass]="{'isMOTD' : isMOTD}">
        {{heading}}
      </h1>
      <p *ngIf="message" class="ion-text-center ov-auto para"
        [ngClass]="{'isMOTDTxt' : isMOTD, 'isHtml' : message | isHtml}" [innerHTML]="message | replaceLineBreaks"></p>

      <ul *ngIf="list?.length" class="listings">
        <li *ngFor="let item of list">
          {{item}}
        </li>
      </ul>
    </div>

    <div class="buttons-section" [ngClass]="{'hrsRequest' : (heading == 'Hour adjustment request')}">
      <ion-button *ngIf="rejectBtnText" id="success-popup-btn"
        class="half-width ion-no-margin btn success-popup-ok remove-bg" (click)='submit("reject")'>
        <span>{{rejectBtnText}}</span>
      </ion-button>

      <ion-button shape="rounded" color="dark" [ngClass]="{'half-width':rejectBtnText}" id="success-popup-btn"
        class=" ion-no-margin btn success-popup-ok" (click)='submit("accept")'>
        {{btnText}}
      </ion-button>
    </div>
  </div>
</div>