import {
  Component,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  
  activeTab: string = 'active';

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit(): void { }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  goBack(): void {
    this.modalController.dismiss();
  }

}
