<div class="cred-instruction-modal-wrapper">
  <div class="details">
    <a class="close-btn" (click)="goBack()">
      <ion-icon name="close-outline"></ion-icon>
    </a>
    <h1>{{ credentialName }}</h1>
    <p>{{ credential.description }}</p>
    <img
      *ngIf="credential.exampleImage"
      [src]="credential.exampleImage"
      [alt]="credential.name + '\'s sample credential'"
    />
  </div>
  <div class="actions">
    <ion-button shape="round" color="dark" (click)="goBack()"
      >Go back and upload</ion-button
    >
  </div>
</div>
