<div class="cred-box" (click)="goToBackgroundCheckScreen()">
  <div class="details">
    Background Check
  </div>
  <div class="actions">
    <ng-container [ngSwitch]="backgroundCheckStatus">
      <div class="rejected" *ngSwitchCase="'rejected'">
        <button class="btn btn-rounded">Rejected</button>
        <ion-icon name="close"></ion-icon>
      </div>
      <div class="pending" *ngSwitchCase="'pending'">
        <ion-icon name="time-outline"></ion-icon>
      </div>
      <div class="pending" *ngSwitchCase="'awaiting_feedback'">
        <ion-icon name="time-outline"></ion-icon>
      </div>
      <div class="actions" *ngSwitchCase="'approved'">
        <ion-icon name="checkmark-sharp"></ion-icon>
      </div>
      <div class="missing" *ngSwitchDefault>
        <ion-icon class="whole-icon" name="arrow-forward-circle"></ion-icon>
      </div>
    </ng-container>
  </div>
</div>
