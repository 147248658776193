<div class="staffer">
  <ion-header [ngClass]="{'toggleColor' : subTitle}">
    <ion-toolbar class="toolbar">
      <ion-buttons slot="start" (click)="navBack()">
        <ion-icon name="arrow-back"></ion-icon><span class="back-txt">Back</span>
      </ion-buttons>
      <ion-title class="sub-title">{{ subTitle }}</ion-title>
    </ion-toolbar>
  </ion-header>
</div>
<ion-content class="ion-page-scroll">
  <ion-router-outlet></ion-router-outlet>
</ion-content>