<div class="main-container">
  <div class="upper-section">
    <div class="head-section">
      <div class="head-and-detail">
        <h1 class="common-head">background</h1>
        <h1 class="common-head main-heading">check</h1>
      </div>
      <div class="underline"></div>
      <p class="note">
        Please confirm your information, it will be used to start the background
        check.
      </p>
    </div>
  </div>

  <div class="main-wrapper">
    <div class="form-wrapper">
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <form [formGroup]="backgroundInformationForm">
              <div class="flex-row">
                <div class="text-and-error">
                  <ion-item class="firstCol">
                    <ion-input
                      class="form-fields firstName"
                      required
                      type="text"
                      formControlName="firstName"
                      placeholder="*First Name"
                    >
                      <ion-icon
                        *ngIf="
                          backgroundInformationForm.controls['firstName'].valid
                        "
                        name="checkmark-outline"
                      ></ion-icon>
                    </ion-input>
                  </ion-item>
                </div>

                <div class="text-and-error padding-left-eight">
                  <ion-item class="secondCol">
                    <ion-input
                      required
                      type="text"
                      formControlName="lastName"
                      placeholder="*Last Name"
                    >
                      <ion-icon
                        *ngIf="
                          backgroundInformationForm.controls['lastName'].valid
                        "
                        name="checkmark-outline"
                      ></ion-icon>
                    </ion-input>
                  </ion-item>
                </div>
              </div>

              <ion-item class="form-item">
                <ion-input
                  class="form-fields userEmail"
                  required
                  type="text"
                  formControlName="email"
                  placeholder="*Email"
                >
                </ion-input>
                <ion-icon name="checkmark-outline"></ion-icon>
              </ion-item>

              <ion-item class="form-item">
                <ion-input
                  required
                  type="text"
                  formControlName="address"
                  class="form-fields address"
                  placeholder="*Address"
                ></ion-input>
              </ion-item>

              <div class="age">
                <p class="dob">Date of birth</p>

                <ion-select
                  class="search-by-position"
                  formControlName="year"
                  [interfaceOptions]="{ cssClass: 'references-select' }"
                  (ionChange)="
                    getDaysInMonth(); setDate(reference); onYearChange()
                  "
                >
                  {{ year?.value }}
                  <ion-select-option value="" disabled>
                    Select Year
                  </ion-select-option>
                  <ion-select-option
                    value="{{ availableYear }}"
                    *ngFor="let availableYear of yearsList; let i = index"
                    >{{ availableYear }}</ion-select-option
                  >
                </ion-select>

                <ion-select
                  class="search-by-position month"
                  formControlName="month"
                  [interfaceOptions]="{ cssClass: 'references-select' }"
                  (ionChange)="
                    getDaysInMonth(); setDate(backgroundInformationForm.value)
                  "
                >
                  <ion-select-option value="" disabled
                    >Select Month</ion-select-option
                  >
                  <ion-select-option value="01">January</ion-select-option>
                  <ion-select-option value="02">February</ion-select-option>
                  <ion-select-option value="03">March</ion-select-option>
                  <ion-select-option value="04">April</ion-select-option>
                  <ion-select-option value="05">May</ion-select-option>
                  <ion-select-option value="06">June</ion-select-option>
                  <ion-select-option value="07">July</ion-select-option>
                  <ion-select-option value="08">August</ion-select-option>
                  <ion-select-option value="09">September</ion-select-option>
                  <ion-select-option value="10">October</ion-select-option>
                  <ion-select-option value="11">November</ion-select-option>
                  <ion-select-option value="12">December</ion-select-option>
                </ion-select>
                <ion-select
                  class="search-by-position"
                  formControlName="day"
                  [interfaceOptions]="{ cssClass: 'references-select' }"
                  (ionChange)="setDate(backgroundInformationForm.value)"
                >
                  <ion-select-option value="" disabled
                    >Select Day</ion-select-option
                  >
                  <ion-select-option
                    value="{{ availableDay }}"
                    *ngFor="
                      let availableDay of reference?.daysList;
                      let i = index
                    "
                    >{{ availableDay }}</ion-select-option
                  >
                </ion-select>
              </div>
            </form>
            <div
              class="button-div"
              *ngIf="backgroundCheckStatus === 'required' || backgroundCheckStatus === 'pending'"
            >
              <ion-button
                [disabled]="!this.backgroundInformationForm.valid"
                class="register-btn"
                color="dark"
                type="submit"
                (click)="submit()"
              >
                <div class="register-txt">Confirm</div>
                <ion-spinner name="lines-sharp-small" *ngIf="isSubmitting"></ion-spinner>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>
