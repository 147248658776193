import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IEmployeeCredential, UploadedCredentials } from '../interfaces/employeeCredential.interface';
import { HttpService } from '../../shared/services/http.service';
import { Location } from '@angular/common';
import { Subscription, SubscriptionLike, filter } from 'rxjs';
import { ICredential } from '../interfaces/credential.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-view-uploaded-credentials',
  templateUrl: './view-uploaded-credentials.component.html',
  styleUrls: ['./view-uploaded-credentials.component.scss'],
})
export class ViewUploadedCredentialsComponent implements OnInit {
  fileType = '';
  isProfilePic: boolean = false;
  stafferApparel: any;
  credential?: ICredential;
  uploadedCredentials: IEmployeeCredential[] = [];
  detail = '';
  isUploadedCredsLoading = false;
  isUserOnDesktop = false;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private location: Location,
    private popupService: PopupService,
    private sharedDataService: SharedDataService,
    private platformService: Platform
  ) {}

  ngOnInit(): void {}

  async ionViewWillEnter() {
    this.isUserOnDesktop = this.platformService.is('desktop');
    this.credential = this.sharedDataService.selectedCredential;
    if (!this.credential) {
      this.moveToHomeScreen();
      return;
    }
    this.fileType = this.credential.name;
    this.detail = this.credential.description;

    try {
      this.isUploadedCredsLoading = true;
      // Fetching employee credentials from server
      const employeeCredentials = await this.httpService.httpGetRequest<UploadedCredentials>(
        `/employee-credentials?credentialId=${this.credential.id}`
      )
      // Mapping to have the right data type
      this.uploadedCredentials = employeeCredentials[this.credential.name].map((cred) => ({
        ...cred,
        fileType: this.getFileType(cred.filepath),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      this.isUploadedCredsLoading = false;
    }
  }

  getFileType(url: string): 'pdf' | 'doc' | 'image' {
    const urlSegments = url.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];
    const fileExtention = lastSegment.split('.')[1];
    if (fileExtention === 'doc' || fileExtention === 'docx') {
      return 'doc';
    } else if (fileExtention === 'pdf') {
      return 'pdf';
    } else {
      return 'image';
    }
  }

  formatName(filename: string) {
    if (filename.includes('/')) {
      return filename.replace('/', ` / `);
    }
    return filename;
  }

  addCertificate(data: {action: 'add' | 'reupload' | 'retake', item: IEmployeeCredential}) {
    this.sharedDataService.uploadingAction = data.action;
    this.sharedDataService.employeeCredentialIdToUpdate = data?.item?.id;

    if (this.isUserOnDesktop) {
      this.popupService.showUploadScreenModal();
      return;
    }

    this.router.navigateByUrl('/credentials/upload', {
      state: { 
        justGoBack: true,
      },
    });
  }

  async removeCertificate(data: IEmployeeCredential) {
    console.log('GOING TO DELETE CERTIFICATE', data);
    await this.httpService.httpDeleteRequest(
      `/employee-credentials/${data.id}`
    );
    this.uploadedCredentials = this.uploadedCredentials.filter(
      (item) => item.id !== data.id
    );
    if (!this.uploadedCredentials.length) {
      this.router.navigateByUrl('/home');
    }
  }

  showInstruction(): void {
    if (this.credential) {
      this.popupService.showCredentialInstructionModal(this.credential);
    }
  }

  moveToHomeScreen() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }
}
