<div class="reset-pass-container">
  <div *ngIf="user">
    <ion-card>
      <div class="company-logo"></div>
      <ion-card-header>
        <ion-card-title class="welcome-text ion-margin-bottom"> Welcome <br> {{ user.name }}!</ion-card-title>
      </ion-card-header>

      <ion-card-content class="card-content">
        <h1 class="title me-2 ms-2">
          {{ !user.isTempPasswordReset ? 'To start please upload a profile picture and set a password' : 'Enter your new password'}}
        </h1>
        <div *ngIf="!user?.isTempPasswordReset" class="profile-pic" (click)="showModal()">
          <img *ngIf="user?.profilePicture" class="profile-img" [src]="user.profilePicture" alt="Profile Photo">
          <ion-icon  name="cloud-upload-outline" class="profile-icon"></ion-icon>
        </div>
        <form
          class="reset-pass-form"
          (ngSubmit)="processForm()"
          [formGroup]="resetPassForm"
        >
          <div class="form-container">
            <div class="input-item input-item-with-icon">
              <ion-label>Password</ion-label>
              <ion-input
                class="app-input"
                placeholder="Enter Password"
                type="password"
                formControlName="password"
              ></ion-input>
              <ion-icon
                name="close-outline"
                size="large"
                *ngIf="!isPassValid"
              ></ion-icon>
            </div>
            <div
              *ngIf="
                password?.invalid && (password?.dirty || password?.touched)
              "
              class="form-text text-danger"
            >
              <div *ngIf="password?.errors?.['required']">
                <small>Password is required</small>
              </div>
            </div>

            <div class="input-item input-item-with-icon ion-margin-top">
              <ion-label>Repeat Password</ion-label>
              <ion-input
                class="app-input"
                placeholder="Repeat Password"
                type="password"
                formControlName="repeatPassword"
              ></ion-input>
              <ion-icon
                name="close-outline"
                size="large"
                *ngIf="!isRepeatPassValid"
              ></ion-icon>
            </div>
            <div
              *ngIf="
                repeatPassword?.invalid && (repeatPassword?.dirty || repeatPassword?.touched)
              "
              class="form-text text-danger"
            >
              <div *ngIf="repeatPassword?.errors?.['required']">
                <small>Repeat Password is required</small>
              </div>
              <div *ngIf="resetPassForm?.errors?.['confirmedValidator']">
                <small>Password & Repeat password should be same</small>
              </div>
            </div>

            <div class="input-item action-container">
              <ion-button type="submit">
                Save Password
                <ion-spinner
                  name="lines-sharp-small"
                  *ngIf="isSubmitting"
                ></ion-spinner>
              </ion-button>
            </div>
          </div>
        </form>
      </ion-card-content>
    </ion-card>
    <div class="footer">
      <div class="powered-by">Powered by</div>
      <img src="../../../assets/images/logo.png" />
    </div>
  </div>
</div>
