import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss'],
})
export class SuccessPopupComponent implements OnInit {

  @Input() data: {
    heading: string;
    message: string;
    btn: string;
    navigateRoute: string;
    imgURL?: string;
    rejectBtnText?: string;
    list?: string[];
    externalNavigationUrl?: string;
    specificClass?: string;
    disableSubmission?: boolean
  } | undefined;
  userData: IUser | undefined;
  specificClass: string | undefined;
  imgURL: string | undefined;
  isMOTD: boolean = false;
  heading: string = '';
  message: string = '';
  list: string[] = [];
  rejectBtnText: string | undefined;
  btnText: string | undefined;

  constructor(
    private router: Router,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    if (this.data?.heading && this.data.message && this.data.btn) {
      this.heading = this.data.heading;
      this.message = this.data.message;
      this.btnText = this.data.btn;
      this.imgURL = this.data.imgURL;
      this.specificClass = this.data.specificClass || '';
    } else {
      this.heading = 'Success';
      this.message = '';
      this.btnText = 'OK';
    }

    console.log('this.specificClass: ', this.specificClass);

    if (this.heading.includes('Message'))
      this.isMOTD = true;

    if (this.data?.rejectBtnText) {
      this.rejectBtnText = this.data.rejectBtnText
    }
    if (this.data?.list && this.data.list.length) {
      this.list = this.data.list
    }
  }

  submit(type: 'accept' | 'reject') {
    if (this.data?.navigateRoute) {
      this.router.navigateByUrl(this.data.navigateRoute);
    } else if (this.data?.externalNavigationUrl && type === 'accept') {
      window.open(this.data.externalNavigationUrl, '_system');
    }

    if (!this.data?.disableSubmission)
      this.modalController.dismiss(type);

    if (this.btnText == 'Contact support') {
      this.modalController.dismiss(type);
    }
  }

  closeMenu () {
    this.modalController.dismiss();
  }

}
