import { HostListener, Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushWooshService {

  private pushNotification: any;

  constructor(
    private platform: Platform
  ) {
    const win = (window as any);
    const isCordova = this.platform.is('capacitor');
    if (isCordova && win?.plugins?.pushNotification){
      this.pushNotification = win.plugins.pushNotification;
    }
  }

  @HostListener('document:push-receive', ['$event'])
  private onPushReceive(e: any): void {
    const message = e.notification.message;
    const userData = e.notification.userdata;

    console.log('Push message received: ' + message);
    console.info(JSON.stringify(e.notification));

    //dump custom data to the console if it exists
    if (typeof userData != 'undefined') {
      console.warn('user data: ' + JSON.stringify(userData));
    }
  }

  @HostListener('document:push-notification', ['$event'])
  private onPushMessageOppened(e: any): void {
    const message = e.notification.message;
    const userData = e.notification.userdata;

    console.log('Push message opened: ' + message);
    console.info(JSON.stringify(e.notification));

    //dump custom data to the console if it exists
    if (typeof userData != 'undefined') {
      console.warn('user data: ' + JSON.stringify(userData));
    }
  }

  initPushwoosh(): void {
    if (!this.pushNotification) {
      return;
    }

    this.pushNotification.onDeviceReady({
      projectid: environment.PUSH_WOOSH_PROJECT_ID,
      appid: environment.PUSH_WOOSH_APP_ID,
      serviceName: '',
    });
  }

  registerDevice(): Promise<string|null> {
    return new Promise((resolve) => {
      if (!this.pushNotification) {
        resolve(null);
      }

      this.pushNotification.registerDevice(
        function (status: any) {
          resolve(status.pushToken);
        },
        function (error: any) {
          console.log('Push woosh service error while registering device ', error);
          resolve(null);
        }
      );
    });
  }

  unregisterDevice(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.pushNotification) {
        resolve();
      }

      this.pushNotification.unregisterDevice(
        function (status: any) {
          resolve(status);
        },
        function (error: any) {
          console.log('Push woosh service error while registering device ', error);
          resolve(error);
        }
      );
    });
  }
}
