import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-instruction',
  templateUrl: './send-instruction.component.html',
  styleUrls: ['./send-instruction.component.scss'],
})
export class SendInstructionComponent  implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  loginAgain() {
    console.log("login-again");
    this.router.navigateByUrl('/login');
  }
}
