import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })

export class RegexService {

    constructor() { }

    public nameRegex() {
        return /^[a-zA-ZÀ-ÿ]+(([’'. -][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ]*)*$/;
    }  

    public companyRegex() {
        return  /^[a-zA-ZÀ-ÿ.]+(([\s]?[’'.&'-`. \s][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ.]*)*$/
    }  

    public emailRegex() {
        return "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,63}?$";
    }
    
    public passwordRegex() {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^()-_+={}~|])[A-Za-z\d$@$!%*#?&^()-_+={}~|]{8,}$/;
    }

}
