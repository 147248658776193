import { Location } from '@angular/common';
import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { CredentialActions, ICredential } from '../interfaces/credential.interface';
import { AlertController, IonicSlides, LoadingController, NavController, Platform } from '@ionic/angular';
import { Swiper, SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';
import { HttpService } from 'src/modules/shared/services/http.service';
import { IEmployeeCredential } from '../interfaces/employeeCredential.interface';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-retake-credential',
  templateUrl: './retake-credential.component.html',
  styleUrls: ['./retake-credential.component.scss'],
})
export class RetakeCredentialComponent implements OnInit, OnDestroy {

  title = "";
  detail = "";
  credential?: ICredential;
  selectedFilesToUpload: File[] = [];
  sliderImages: Array<{url: string, type: string, name: string}> = [];
  
  fileUploadLoader?: any;
  employeeCredentialId: number = 0;
  credentialAction: CredentialActions = 'add';
  queryParams: any;
  router$?: Subscription;
  isUserOnDesktop = false;

  swiperModules = [IonicSlides];
  slideConfig: SwiperOptions = {
    slidesPerView: 1.3,
    spaceBetween: 30,
    centeredSlides: true,
    effect: 'coverflow',
    coverflowEffect: {
      slideShadows: false,
      depth: 300,
      rotate: 0,
    }
  }
  @ViewChild('swiper')
  swiperRef?: ElementRef<SwiperContainer>;

  constructor(
    private router: Router,
    private location: Location,
    private popupService: PopupService,
    private httpService: HttpService,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private platformService: Platform,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit() {
    // Listening to router event because in desktop we have to redirect
    // on the same retake screen multiple times whenever image upload array updates
    this.router$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects === "/credentials/retake") {
        this.ionViewWillEnter();
        this.updateSlides();
      }
    });
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }

  updateSlides(): void {
    // Wrapping in timeout to run after slides get updated
    setTimeout(() => {
      this.swiperRef?.nativeElement?.swiper?.updateSlides();
    });
  }

  ionViewWillEnter() {
    this.isUserOnDesktop = this.platformService.is('desktop');
    this.credential = this.sharedDataService.selectedCredential;
    this.title = this.credential?.name || '';
    this.detail = this.credential?.description || '';
    this.selectedFilesToUpload = this.sharedDataService.selectedFilesToUpload || [];
    if (!this.selectedFilesToUpload.length) {
      this.moveToHomeScreen();
      return;
    }

    this.sliderImages = this.selectedFilesToUpload.map(
      (file: File) => ({url: URL.createObjectURL(file), type: this.getFileType(file.name), name: file.name})
    );

    if (this.sharedDataService.employeeCredentialIdToUpdate) {
      this.employeeCredentialId = this.sharedDataService.employeeCredentialIdToUpdate;
    }
    this.credentialAction = this.sharedDataService.uploadingAction || 'add';
  }

  ionViewDidEnter() {
    // Initialize swiper slider withh all config params
    if (this.swiperRef?.nativeElement) {
      if (this.isUserOnDesktop) {
        this.slideConfig = {
          ...this.slideConfig,
          slidesPerView: 1.5,
        }
      }
      // now we need to assign all parameters to Swiper element
      Object.assign(this.swiperRef.nativeElement, this.slideConfig);
      // and now initialize it
      this.swiperRef.nativeElement.initialize();
    }
  }

  doDelete(index: number): void {
    this.selectedFilesToUpload = this.selectedFilesToUpload.filter((_, i) => i !== index);
    this.sharedDataService.selectedFilesToUpload = this.selectedFilesToUpload;
    this.sliderImages = this.sliderImages.filter((_, i) => i !== index);
    this.swiperRef?.nativeElement.swiper.removeSlide(index);
  }

  handleDelete(index: number): void {
    this.doDelete(index);
    // If all files are deleted from the slider
    if (!this.selectedFilesToUpload.length) {
      if (this.isUserOnDesktop) {
        this.moveToHomeScreen();
      } else {
        // Redirect back with no selected files to upload
        this.doAddMore(false);
      }
    }
  }

  doRetake(index: number): void {
    this.doDelete(index);
    this.doAddMore();
  }

  doAddMore(justGoBack = true): void {
    // Set credential in shared data service to use it in other screens
    this.sharedDataService.selectedCredential = this.credential;
    this.sharedDataService.selectedFilesToUpload = this.selectedFilesToUpload;
    this.sharedDataService.uploadingAction = 'add';
    
    if (this.isUserOnDesktop) {
      this.popupService.showUploadScreenModal();
      return;
    }

    this.router.navigate(['/credentials/upload'], {
      state: { 
        justGoBack,
      },
    });
  }

  showInstruction(): void {
    if (this.credential) {
      this.popupService.showCredentialInstructionModal(this.credential);
    }
  }

  getFileType(name: string): 'pdf' | 'doc' | 'image' | string {
    const fileExtention = name.split('.')[name.split('.').length - 1];
    if (fileExtention === 'doc' || fileExtention === 'docx' || fileExtention == 'document') {
      return 'doc';
    } else if (fileExtention === 'png' || fileExtention === 'jpg' || fileExtention === 'jpeg') {
      return 'image';
    } else {
      return fileExtention;
    }
  }


  showPopup(heading: string, message: string, imgURL: string | null) {
    this.popupService.showModal({
      heading,
      message,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL
    });
  }

  async showExpiryModal(employeeCredentialId: number, credentialId: number, extractedExpiryDate: Date = new Date()) {
    const modalData = await this.popupService.showExpiryModal(extractedExpiryDate);
    if (modalData.role === 'success' && modalData.data.selectedDate) {
      await this.httpService.httpPutRequest(`/employee-credentials/${employeeCredentialId}`, { expiryDate: new Date(modalData.data.selectedDate), credentialId });
    }
  }

  async submit() {
    if (!this.selectedFilesToUpload || this.selectedFilesToUpload.length === 0) {
      this.showPopup('Sorry', `no file to upload`, 'assets/images/sorry1.png');
      return;
    }

    try {
      // Show loader
      await this.showUploadLoader();
      // Making form data to send file
      const currentSlideIndex = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
      const formData = new FormData();
      const fileToUpload = this.selectedFilesToUpload[currentSlideIndex];
      formData.append('file', fileToUpload);
      formData.append('credentialId', `${this.credential?.id || ''}`);
      // making API URL to manage reupload/retake case
      let url = '/employee-credentials';
      if (this.credentialAction === 'reupload' && this.employeeCredentialId) {
        url = `/employee-credentials/reupload/${this.employeeCredentialId}`;
      } else if (this.credentialAction === 'retake' && this.employeeCredentialId) {
        url = `/employee-credentials/retake/${this.employeeCredentialId}`;
      }
      // sending API request
      const res = await this.httpService.httpPostRequest<IEmployeeCredential>(url, formData);
      // hiding loader
      this.hideUploadLoader();
      // Extracting expiry date to show expiry modal
      const extractedExpiryDate = this.getExtractedExpiryDate(res?.extractedDetails);
      if (extractedExpiryDate && res) {
        await this.showExpiryModal(res?.id, res?.credentialId, new Date(extractedExpiryDate));
      }
      // Removing submitted slide
      this.doDelete(currentSlideIndex);
      // If all slides submitted redirect to uploaded screen
      if (!this.selectedFilesToUpload.length) {
        this.moveToUploadedScreen();
      }
    } catch (error) {
      console.log('Error while uploading file ... ', error);
      this.hideUploadLoader()
      await this.showErrorAlert(error);
    }
  }

  async showErrorAlert(error: any): Promise<void> {
    this.popupService.showErrorModal(
      error?.error?.message || 'Either file is too large or format not supported.'
    );
  }

  async showUploadLoader() {
    this.fileUploadLoader = await this.loadingCtrl.create({
      message: 'Uploading...',
    });
    this.fileUploadLoader.present();
  }

  hideUploadLoader() {
    this.fileUploadLoader?.dismiss();
  }

  getExtractedExpiryDate(extractedData: any) {
    const expiryDateFields = ['expirationDate', 'expiryDate', 'expires', 'expire', 'validTill'];
    for (const field of expiryDateFields) {
      if (extractedData?.fields && extractedData?.fields[field]) {
        return extractedData.fields[field].value;
      }
    }
    return null;
  }

  moveToUploadedScreen() {
    this.navCtrl.navigateBack('/credentials/uploaded');
  }

  moveToHomeScreen() {
    this.router.navigateByUrl('/home');
  }

}
