import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// guards
import { canActivateWithAuth } from './guards/auth.guard';
import { canActivateWithoutAuth } from './guards/unauth.guard';


// components
import { LoginComponent } from '../modules/authentication/login/login.component';
import { HomeComponent } from '../modules/home/home.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { HeaderWithBackBtnComponent } from './layout/header-with-back-btn/header-with-back-btn.component';
import { UploadCredentialComponent } from '../modules/credentials/upload-credential/upload-credential.component';
import { ViewUploadedCredentialsComponent } from '../modules/credentials/view-uploaded-credentials/view-uploaded-credentials.component';
import { ResetPasswordComponent } from 'src/modules/authentication/reset-password/reset-password.component';
import { AddReferenceFormComponent } from 'src/modules/credentials/add-reference-form/add-reference-form.component';
import { BackgroundCheckComponent } from '../modules/credentials/background-check/background-check.component';
import { RetakeCredentialComponent } from 'src/modules/credentials/retake-credential/retake-credential.component';
import { ForgotPasswordComponent } from 'src/modules/authentication/forgot-password/forgot-password.component';
import { SendInstructionComponent } from 'src/modules/authentication/send-instruction/send-instruction.component';

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [canActivateWithoutAuth],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    // canActivate: [canActivateWithAuth],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'send-instruction',
    component: SendInstructionComponent,
  },
  {
    path: 'home',
    canActivate: [canActivateWithAuth],
    component: SideMenuComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      }
    ]
  },
  {
    path: 'credentials',
    canActivate: [canActivateWithAuth],
    component: HeaderWithBackBtnComponent,
    data: { backPath: 'home' },
    children: [
      {
        path: 'upload',
        component: UploadCredentialComponent
      },
      {
        path: 'retake',
        component: RetakeCredentialComponent
      },
      {
        path: 'uploaded',
        component: ViewUploadedCredentialsComponent,
      },
      {
        path: 'references',
        component: AddReferenceFormComponent
      },
      {
        path: 'background-check',
        component: BackgroundCheckComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
