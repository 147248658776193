<div class="main-container">
  <div class="upper-section">
    <div class="head-section">
      <div class="head-and-detail">
        <h1 class="common-head"
          [ngClass]="{'certificate-heading': (fileType != 'apparel' && !isProfilePic), 'apparel-heading' : fileType == 'apparel', 'main-heading' : fileType != 'apparel'}"
          *ngIf="fileType != 'apparel'">
          {{ formatName(fileType) }}
          <span *ngIf="fileType != 'apparel' && !isProfilePic">
            <ion-icon name="help-circle" class="question-icon" (click)="showInstruction()"></ion-icon>
          </span>
        </h1>
        <h1 class="common-head"
          [ngClass]="{'apparel-heading' : fileType == 'apparel', 'main-heading' : fileType != 'apparel'}"
          *ngIf="fileType == 'apparel'">{{ stafferApparel?.name }}</h1>
      </div>
      <div class="underline"></div>
      <p class="note" *ngIf="!isProfilePic && fileType != 'apparel'">{{ detail }}</p>
      <p class="note" *ngIf="isProfilePic">Upload your most professional, clear headshot.</p>
      <p class="note" *ngIf="fileType == 'apparel'">Upload photos of your uniform/apparel.</p>
    </div>

    <div *ngIf="isUploadedCredsLoading" class="spinner-container">
      <ion-spinner name="lines"></ion-spinner>
    </div>

    <app-view-credentials
      *ngIf="uploadedCredentials.length > 0"
      [uploadedCredentials]="uploadedCredentials" 
      (addCertificate)="addCertificate($event)"
      (removeCertificate)="removeCertificate($event)">
    </app-view-credentials>
    
    <!-- This button is here for ui only -->
    <div *ngIf="uploadedCredentials.length > 0" class="actions">
      <ion-button shape="round" color="dark" disabled>Save</ion-button>
    </div>

  </div>

</div>