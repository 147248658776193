import { Component } from '@angular/core';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { register } from 'swiper/element/bundle';
import { PushWooshService } from 'src/modules/shared/services/pushWoosh.service';

// register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private localStorageService: LocalStorageService,
    private pushWooshService: PushWooshService
  ) { 
    register();
  }

  async ngOnInit() {
    await this.localStorageService.init();
    // Initializing push woosh SDK
    this.pushWooshService.initPushwoosh();
  }

}
