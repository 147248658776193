import { Injectable, inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { LocalStorageService } from '../../modules/shared/services/local-storage.service';


@Injectable()
export class UnAuthGuardService {

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    async canActivate(): Promise<boolean> {
        const user = await this.localStorageService.getLoggedInUserInfo();
        if (!user) {
            return true;
        }
        this.router.navigateByUrl('/home');
        return false;
    }
}

export const canActivateWithoutAuth: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const canActivate: boolean = await inject(UnAuthGuardService).canActivate();
    return canActivate;
}
