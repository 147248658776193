import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-with-back-btn',
  templateUrl: './header-with-back-btn.component.html',
  styleUrls: ['./header-with-back-btn.component.scss'],
})
export class HeaderWithBackBtnComponent implements OnInit {

  route$?: Subscription;
  @Input() backPath: string = '';
  @Input() subTitle: string = '';
  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
    this.route$ = this.route.data.subscribe(
      (data: any) => {
        this.subTitle = data.subTitle;
        this.backPath = data.backPath;
      }
    )
  }

  ionViewWillLeave(): void {
    this.route$?.unsubscribe();
  }

  navBack() {
    const state = this.location.getState() as { justGoBack?: boolean };
    if (state?.justGoBack){
      this.navCtrl.back();
    } else if (this.backPath) {
      this.navCtrl.navigateBack(this.backPath);
    } else {
      this.navCtrl.pop();
    }
  }

}
