import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupService } from '../../shared/services/popup.service';
import { ChoosePictureModalComponent } from '../../shared/components/choose-picture-modal/choose-picture-modal.component';
import { AlertController, LoadingController, ModalController, NavController, ViewWillEnter } from '@ionic/angular';
import { HttpService } from '../../shared/services/http.service';
import { Location } from '@angular/common';
import { CredentialActions, ICredential } from '../interfaces/credential.interface';
import { SharedDataService } from 'src/modules/shared/services/shared-data.service';

@Component({
  selector: 'app-upload-credential',
  templateUrl: './upload-credential.component.html',
  styleUrls: ['./upload-credential.component.scss'],
})
export class UploadCredentialComponent implements OnInit, ViewWillEnter {

  fileType = '';
  isProfilePic: boolean = false;
  stafferApparel: any;
  detail?: string;
  allowUploading: boolean = true
  isIOS: boolean = false;
  credential?: ICredential;
  previouslySelectedFiles: File[] = [];
  credentialAction?: CredentialActions;
  
  @Input() type?: string;

  constructor(
    private popupService: PopupService,
    private modalController: ModalController,
    private sharedDataService: SharedDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  ionViewWillEnter(): void {
    this.loadData();
  }

  loadData(): void {
    this.credential = this.sharedDataService.selectedCredential;
    if (!this.credential) {
      this.moveToHomeScreen();
      return;
    }
    this.fileType = this.credential?.name || '';
    this.detail = this.credential?.description;
    this.previouslySelectedFiles = this.sharedDataService.selectedFilesToUpload || [];
    this.credentialAction = this.sharedDataService.uploadingAction;
  }

  formatName(filename: string) {
    if (filename.includes('/')) {
      return filename.replace('/', ` / `);
    }
    return filename;
  }

  async showModal(category: string) {

    console.log(category);

    const myModal = await this.modalController.create({

      component: ChoosePictureModalComponent,
      cssClass: 'uploadImgModal',
      componentProps: {
        selectImage: false,
        type: 'staffer'
      }
    });

    myModal.onDidDismiss().then(async (dataReturned) => {
      if (dataReturned?.data) {
        if (dataReturned.data.filePath) {
          let blob = await fetch(dataReturned.data.filePath.webPath).then(r => r.blob());
          // this.submit([blob], this.fileType, this.credentialAction);
          const file = new File([blob], Date.now() + '.jpg', {type:"image/jpg"});
          this.moveToRetakeScreen([file]);
        } else {
          dataReturned.data.fd.forEach((file: File) => {
            const renamedFile = new File([file], Date.now() + '.jpg', {type:"image/jpg"});
            this.moveToRetakeScreen([renamedFile]);
          })
        }
      }
    }

    );
    return await myModal.present();
  }

  async uploadFile(imageInput: any) {
    const files = [];
    for (const file of imageInput.files) {
      files.push(await this.checkValidityAndConvertFileType(file));
    }
    this.uploadCertificate(files);
  }

  async uploadCertificate(files: File[]) {
    if (!files?.length) {
      this.showPopup('Sorry', 'Unable to detect file no file found', 'assets/images/sorry1.png');
      return;
    }
    this.moveToRetakeScreen(files);
  }

  moveToRetakeScreen(files: File[]): void {
    const previouslySlectedFileNames = this.previouslySelectedFiles.map(file => file.name);
    // looping through for-of instead of filter to avoid file order/position changes
    const selectedFilesToUpload = [...this.previouslySelectedFiles];
    // Removing duplicates
    for (const file of files) {
      if (!previouslySlectedFileNames.includes(file.name)) {
        selectedFilesToUpload.push(file);
      }
    }
    // Setting shared data to use in next screen
    this.sharedDataService.selectedCredential = this.credential;
    this.sharedDataService.selectedFilesToUpload = selectedFilesToUpload;
    this.sharedDataService.uploadingAction = this.credentialAction;
    // Redirecting to retake screen 
    this.router.navigate(['/credentials/retake'], {
      onSameUrlNavigation: 'reload',
    });
    // Close upload if on desktop
    this.modalController.dismiss();
  }

  // check if the file is .ai so it will change the type and 
  // extention to pdf and return a new file
  // if not an .ai file it will return the file as it is
  async checkValidityAndConvertFileType(file: any) {
    const fileExt = this.getFileExtention(file);
    if (fileExt.toLowerCase() === 'ai') {
      file = await this.convertFileToPdf(file);
    }
    return file;
  }

  // return the extention type of the file
  getFileExtention(file: any) {
    const splitedName = file.name.split('.');
    return splitedName[splitedName.length - 1];
  }

  // convert file type and file extention to supported pdf
  async convertFileToPdf(file: any) {
    const nameArr = file.name.split('.');
    nameArr.length = nameArr.length - 1;
    const base64 = await this.getBase64(file) as string;
    let arr: any = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // create a file object from base64 with the pdf extention and pdf file type
    // return new File([u8arr], nameArr + '.pdf', {type:mime});
    const blob: Blob & { name?: string } = new Blob([u8arr], { type: mime });
    blob.name = nameArr + '.pdf';
    // console.log('createObjectURL', URL.createObjectURL(blob));
    return blob;
  }

  // return bas64 string of the file provided as a parameter
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64 = reader.result as string;
        const base64Arr = base64.split(';')
        // change the file type to pdf
        base64Arr[0] = 'data:application/pdf'
        resolve(base64Arr.join(';'));

      };
      reader.onerror = function (error) {
        reject(error);
      };
    })
  }

  showPopup(heading: string, message: string, imgURL: string | null) {
    this.popupService.showModal({
      heading,
      message,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL
    });
  }

  showInstruction(): void {
    if (this.credential) {
      this.popupService.showCredentialInstructionModal(this.credential);
    }
  }

  get allowMultipleFiles() {
    return this.credentialAction !== 'retake' && this.credentialAction !== 'reupload';
  }

  moveToHomeScreen() {
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

}
