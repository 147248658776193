import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { PopupService } from '../../services/popup.service';
import { ModalController, Platform } from '@ionic/angular';
import { SharedDataService } from '../../services/shared-data.service';
import { UploadCredentialModalComponent } from 'src/modules/credentials/upload-credential-modal/upload-credential-modal.component';

@Component({
  selector: 'app-credential-bar',
  templateUrl: './credential-bar.component.html',
  styleUrls: ['./credential-bar.component.scss'],
})
export class CredentialBarComponent  implements OnInit {

  @Input()
  credential!: ICredential;

  @Input()
  credentialStatus?: 'pending' | 'approved' | 'rejected' | 'expired';

  constructor(
    private router: Router,
    private popupService: PopupService,
    private platformService: Platform,
    private modalController: ModalController,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {}

  goToUploadScreen(credential: ICredential): void {
    // Set credential in shared data service to use it in other screens
    this.sharedDataService.selectedCredential = this.credential;
    this.sharedDataService.selectedFilesToUpload = [];

    const isDesktop = this.platformService.is('desktop');
    if (isDesktop) {
      this.popupService.showUploadScreenModal();
      return;
    }

    this.router.navigateByUrl('/credentials/upload');
  }

  goToUploadedScreen(credential: ICredential): void {
    this.sharedDataService.selectedCredential = credential;
    this.router.navigateByUrl('/credentials/uploaded');
  }

  showReuploadReasonModal(credential: ICredential): void {
    this.popupService.showDisapprovalReasonModal(credential);
  }

  showExpiredCredentialModal(credential: ICredential): void {
    this.popupService.showExpiredCredentialModal(()=> this.goToUploadedScreen(credential));
  }
}
