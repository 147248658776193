<div class="main-container" [ngClass]="{insideModal: type === 'modal'}">
  <div class="upper-section">
    <div class="head-section">
      <div class="head-and-detail">
        <h1 class="common-head"
          [ngClass]="{'certificate-heading': (fileType != 'apparel' && !isProfilePic), 'apparel-heading' : fileType == 'apparel', 'main-heading' : fileType != 'apparel'}"
          *ngIf="fileType != 'apparel'">
          {{ formatName(fileType) }}
          <span *ngIf="fileType != 'apparel' && !isProfilePic">
            <ion-icon name="help-circle" class="question-icon" (click)="showInstruction()"></ion-icon>
          </span>
        </h1>
        <h1 class="common-head"
          [ngClass]="{'apparel-heading' : fileType == 'apparel', 'main-heading' : fileType != 'apparel'}"
          *ngIf="fileType == 'apparel'">{{ stafferApparel?.name }}</h1>
      </div>
      <div class="underline"></div>
      <p class="note" *ngIf="!isProfilePic && fileType != 'apparel'">{{ detail }}</p>
      <p class="note" *ngIf="isProfilePic">Upload your most professional, clear headshot.</p>
      <p class="note" *ngIf="fileType == 'apparel'">Upload photos of your uniform/apparel.</p>
    </div>

    <div class="middle-section" *ngIf="allowUploading">
      <div class="upload take-photo" *ngIf="(isIOS && (isProfilePic || fileType == 'apparel')) || isIOS == false">
        <ion-icon name="camera" class="camera-icon"></ion-icon>
        <div class="lower-section">
          <div class="remaining-div">
            <div class="next-btn">
              <ion-button class="apply-btn certificate-width" type="submit" color="dark" (click)="showModal('CAMERA')">
                <div class="upload-file-container">
                  <ion-label>Take photo</ion-label>
                  <div class="ellipse">
                    <ion-icon name="arrow-forward"></ion-icon>
                  </div>
                </div>
              </ion-button>
            </div>
          </div>
        </div>
      </div>

      <p class="note" *ngIf="!isIOS">Or</p>


      <div class="upload" [ngClass]="{'ios' : (isIOS && (!isProfilePic && fileType != 'apparel'))}">
        <ion-icon name="cloud-upload" class="file-upload-icon"></ion-icon>

        <div class="lower-section">
          <div class="remaining-div">
            <div class="next-btn">
              <ion-button class="apply-btn" color="dark"
                [ngClass]="{'file-width' : isProfilePic, 'certificate-width' : !isProfilePic}">
                <input type="file" [multiple]="allowMultipleFiles" class="apply-txt custom-file-input"
                  [ngClass]="{'file-content' : isProfilePic, 'certificate-content' : !isProfilePic}" #imageInput
                  (change)="uploadFile(imageInput)" />
                <div class="upload-file-container">
                  <ion-label>Upload file</ion-label>
                  <div class="ellipse">
                    <ion-icon name="arrow-forward"></ion-icon>
                  </div>
                </div>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <div class="submit-btn-container">
        <ion-button class="submit-btn" color="dark">
          <ion-label>Submit</ion-label>
        </ion-button>
      </div>
    </div> -->

  </div>

</div>