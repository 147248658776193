// This Service is used for HTTP calls.
/***************** Import angular modules *******************/
import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpClient,
    HttpHeaders
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { firstValueFrom } from 'rxjs';

/*************************** End ****************************/

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) { }

    // HTTP POST request without headers content-type
    async httpPostRequest<T = object>(url: string, data: any) {
        const headers = await this.getTokenAppendHeader();
        const requestURL = `${environment.API_BASE_URL}${url}`;
        const res$ = this.http
            .post<T>(requestURL, data, { headers });
        return firstValueFrom(res$);
    }

    // HTTP GET request
    async httpGetRequest<T = object>(url: string) {
        const headers = await this.getTokenAppendHeader();
        const requestURL = `${environment.API_BASE_URL}${url}`;
        const res$ = this.http
            .get<T>(requestURL, { headers });
        return firstValueFrom(res$);
    }

    // HTTP PUT request
    async httpPutRequest<T = object>(url: string, data: any) {
        const headers = await this.getTokenAppendHeader();
        const requestURL = `${environment.API_BASE_URL}${url}`;
        const res$ = this.http
            .put<T>(requestURL, data, { headers });
        return firstValueFrom(res$);
    }

    // HTTP PATCH request
    async httpPatchRequest<T = object>(url: string, data: any) {
        const headers = await this.getTokenAppendHeader();
        const requestURL = `${environment.API_BASE_URL}${url}`;
        const res$ = this.http
            .patch<T>(requestURL, data, { headers });
        return firstValueFrom(res$);
    }

    // HTTP DELETE request
    async httpDeleteRequest(url: string): Promise<any> {
        const headers = await this.getTokenAppendHeader();
        const requestURL = `${environment.API_BASE_URL}${url}`;
        const res$ = this.http
            .delete(requestURL, { headers })
        return firstValueFrom(res$);
    }


    private async getTokenAppendHeader() {
        let headers = new HttpHeaders();
        const authToken = await this.localStorageService.getLoggedInUserToken();
        // headers = headers.set('Content-Type', 'application/json');
        if (authToken) {
            headers = headers.set('authorization', `Bearer ${authToken}`);
        }
        return headers;
    }

}
