import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../modules/shared/services/local-storage.service';
import { HeaderService } from 'src/modules/shared/services/header.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationModalComponent } from 'src/modules/shared/components/notification-modal/notification-modal.component';
import { ThemeService } from 'src/modules/shared/services/theme.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public userName = '';
  public logo = '';

  constructor(
    private menuController: MenuController,
    private localStorageService: LocalStorageService,
    private headerService: HeaderService,
    private modalController: ModalController,
    private themeService: ThemeService,
  ) { }

  async ngOnInit() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    this.userName = user.name.split(' ')[0];
    this.logo = user.organization.logo || 'assets/images/login-logo.png';
    // show action required CTA if notification unread count > 0
    this.headerService.showActionRequiredCTA();
    // set theme via already cached public settings on login screen
    this.themeService.setThemeViaCache();
  }

  async showModal() {
    const myModal = await this.modalController.create({
      component: NotificationModalComponent,
      cssClass: 'notificationModal',
    });
    await myModal.present();
    return;
  }

  openMenu() {
    console.log("Open menu");
    this.menuController.open('menuContent');
  }

  get urgentCredentialsCount(): BehaviorSubject<number> {
    return this.headerService.urgentCredentialsCount;
  }

  get showActionRequired(): BehaviorSubject<boolean> {
    return this.headerService.showActionRequired;
  }

}
