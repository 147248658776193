import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { Router } from '@angular/router';
import { UploadedCredentials } from 'src/modules/credentials/interfaces/employeeCredential.interface';
import { HttpService } from '../../services/http.service';
import { SharedDataService } from '../../services/shared-data.service';

@Component({
  selector: 'disapprove-reason-modal',
  templateUrl: './disapprove-reason-modal.component.html',
  styleUrls: ['./disapprove-reason-modal.component.scss'],
})
export class DisapproveReasonModalComponent implements OnInit {
  @Input() credential!: ICredential;

  isUploadedCredsLoading = false;
  rejectReason: string | null = null;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private httpService: HttpService,
    private alertController: AlertController,
    private sharedDataService: SharedDataService,
  ) {}

  async ngOnInit() {
    try {
      this.isUploadedCredsLoading = true;
      // Fetching employee credentials from server
      const employeeCredentials = await this.httpService.httpGetRequest<UploadedCredentials>(
        `/employee-credentials?credentialId=${this.credential.id}`
      )
      const uploadedCredentials = employeeCredentials[this.credential.name];
      const rejectedCertificate = uploadedCredentials.find(c => c.status === 'rejected');
      this.rejectReason = rejectedCertificate?.rejectReason || null;
    } catch (error) {
      const errorAlert = await this.alertController.create({
        header: 'Oops!',
        message: 'Something went wrong, please try again.'
      })
      await errorAlert.present();
      console.log(error);
    } finally {
      this.isUploadedCredsLoading = false;
    }
  }

  goBack(): void {
    this.modalController.dismiss();
  }

  reupload(): void {
    this.sharedDataService.selectedCredential = this.credential;
    this.router.navigateByUrl('/credentials/uploaded');
    this.modalController.dismiss();
  }
}
