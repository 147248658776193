<div class="main-header">
  <!-- Notifications title -->
  <div class="main-content">
    <h2 class="heading">Notifications</h2>
    <button (click)="goBack()" class="cancel-btn">
      <ion-icon name="close" size="large" color="black"></ion-icon>
    </button>
  </div>

  <!-- Tab bar -->
  <div class="main-tabs">
    <span
      (click)="switchTab('active')"
      [ngClass]="{'active': activeTab === 'active'}">
      Active
    </span>
    <span
      (click)="switchTab('completed')"
      [ngClass]="{'active': activeTab === 'completed'}">
      Completed
    </span>
  </div>

  <!-- Notifications tabs -->
  
  <!-- Active Tab -->
  <app-notification-tab [type]="'active'" *ngIf="activeTab === 'active'"></app-notification-tab>
  <!-- Completed Tab -->
  <app-notification-tab [type]="'completed'" *ngIf="activeTab === 'completed'"></app-notification-tab>

</div>

