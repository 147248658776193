<div class="login-container">
  <div>
    <ion-card>
      <div class="company-logo"></div>
      <ion-card-header>
        <ion-card-title>Credential Management</ion-card-title>
      </ion-card-header>

      <ion-card-content class="card-content">
        <h1 class="title">Log-in</h1>
        <form class="login-form " (ngSubmit)="processForm()" [formGroup]="loginForm">
          <div class="form-container">
            <div class="input-item input-item-with-icon">
              <ion-label>E-mail</ion-label>
              <ion-input class="app-input" placeholder="Enter Email" formControlName="email"></ion-input>
              <ion-icon name="checkmark-outline" size="large" *ngIf="isEmailValid"></ion-icon>
            </div>

            <div class="input-item input-item-with-icon">
              <div class="justify-input-text">
                <ion-label>Password</ion-label>
                <a (click)="forgotPass()" class="forgot-password-text">Forgot password?</a>
              </div>
              <ion-input class="app-input" placeholder="Enter Password" type="password"
                formControlName="password"></ion-input>
              <ion-icon name="close-outline" size="large" *ngIf="!isPassValid" (click)="resetPass()"></ion-icon>
            </div>

            <div class="input-item action-container">
              <ion-button type="submit">
                Log-in
                <ion-spinner name="lines-sharp-small" *ngIf="isSubmitting"></ion-spinner>
              </ion-button>
            </div>

          </div>
        </form>
      </ion-card-content>
    </ion-card>
    <div class="footer">
      <div class="powered-by">
        Powered by
      </div>
      <img src="../../../assets/images/logo.png" />
    </div>

  </div>
</div>