import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-upload-credential-modal',
  templateUrl: './upload-credential-modal.component.html',
  styleUrls: ['./upload-credential-modal.component.scss'],
})
export class UploadCredentialModalComponent  implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  closeModal(): void {
    this.modalController.dismiss();
  }
}
