import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';


@Component({
  selector: 'app-set-expiry-modal',
  templateUrl: './set-expiry-modal.component.html',
  styleUrls: ['./set-expiry-modal.component.scss'],
})
export class SetExpiryModalComponent implements OnInit {

  @Input()
  extractedExpiryDate: Date | undefined;
  selectedDate: string = moment().format('YYYY-MM-DD');
  maxDate: string = moment().add(10, 'years').endOf('year').format('YYYY-MM-DD');
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    
    if (this.extractedExpiryDate) {
      this.selectedDate = moment(this.extractedExpiryDate).format('YYYY-MM-DD');
      console.log("this.selectedDate", this.selectedDate)
    }
  }

  saveDate() {
    console.log(this.selectedDate);
    this.modalController.dismiss({ selectedDate: this.selectedDate }, 'success');
  }

  onChangeDate(e: any){
    this.selectedDate = e.target.value;
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
