import { Injectable } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';

import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    constructor(
    ) { }

    choosePicture(srcType: CameraSource) {
        const options: ImageOptions = {
            quality: 75,
            // destinationType: this.camera.DestinationType.FILE_URI,
            // sourceType: srcType === 'CAMERA' ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY,
            // encodingType: this.camera.EncodingType.JPEG,
            // targetWidth: 720,
            // saveToPhotoAlbum: false,
            // correctOrientation: true
            resultType: CameraResultType.Uri,
            saveToGallery: false,
            correctOrientation: true,
            width: 720,
            source: srcType
        };

        // return from(Camera.getPhoto(options)) as Observable<string>;
        return Camera.getPhoto(options)
    }
}
