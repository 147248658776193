import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { AddressModificationService } from 'src/modules/shared/services/address-modification.service';
import { HttpService } from '../../shared/services/http.service';
import { BackgroundCheckStatus } from 'src/modules/authentication/interfaces/user.interface';
import { Router } from '@angular/router';
import { PopupService } from '../../shared/services/popup.service';

interface Reference {
  day: Array<string>;
  endDay: Array<string>;
  month: string;
  year: string;
  daysList: Array<string>;
}

@Component({
  selector: 'app-background-check',
  templateUrl: './background-check.component.html',
  styleUrls: ['./background-check.component.scss'],
})
export class BackgroundCheckComponent implements OnInit {
  backgroundInformationForm: FormGroup;
  reference: Reference = {
    day: [],
    endDay: [],
    month: '',
    year: '',
    daysList: [],
  };
  yearsList: string[] = [];
  currentYear = moment().year();
  backgroundCheckStatus: BackgroundCheckStatus =
    BackgroundCheckStatus.NOT_REQUIRED;
  isSubmitting: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private addressModificationService: AddressModificationService,
    private httpService: HttpService,
    private router: Router,
    private popupService: PopupService
  ) {
    this.backgroundInformationForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      address: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),

      year: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      day: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
    });
    for (let i = 0; i <= 100; i++) {
      this.yearsList.push((this.currentYear - i).toString());
    }
  }

  async ngOnInit() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    if (user) {
      this.backgroundCheckStatus = user.backgroundCheckStatus;
      const firstName = user.name?.split(' ')[0];
      const lastName = user.name?.split(' ')[1] || '';
      this.backgroundInformationForm.get('firstName')?.setValue(firstName);
      this.backgroundInformationForm.get('lastName')?.setValue(lastName);
      this.backgroundInformationForm.get('email')?.setValue(user.email);
      this.backgroundInformationForm
        .get('address')
        ?.setValue(
          this.addressModificationService.updateUserStreet(user.location)
        );
      if (user.dob) {
        const dateOfBirth = moment(user.dob);

        this.backgroundInformationForm
          .get('year')
          ?.setValue(dateOfBirth.format('YYYY'));
        this.backgroundInformationForm
          .get('month')
          ?.setValue(dateOfBirth.format('MM'));
        this.backgroundInformationForm
          .get('date')
          ?.setValue(dateOfBirth.format('YYYY-MM-DD'));
        this.getDaysInMonth();
        this.backgroundInformationForm
          .get('day')
          ?.setValue(Number(dateOfBirth.format('DD')).toString());
      }
    }
  }

  getDaysInMonth() {
    // get days in the selected month of the selected year
    const daysInMonth = moment(
      `${this.year?.value}-${this.month?.value}`,
      'YYYY-MM'
    ).daysInMonth();
    // push days numbers in dayslist array
    const daysList = [];
    for (let day = 1; day <= daysInMonth; day++) {
      daysList.push(day.toString());
    }
    if (this.day && !daysList.includes(this.day.value)) {
      this.day.setValue('1');
    }
    this.reference.daysList = daysList;
  }

  async setDate(reference: Reference) {
    let { year, month, day } = reference;

    if (year && month && day) {
      this.date?.setValue(
        moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD')
      );
    }
  }

  onYearChange() {
    this.month?.setValue('');
    this.day?.setValue('');
  }

  get day() {
    return this.backgroundInformationForm.get('day');
  }
  get date() {
    return this.backgroundInformationForm.get('date');
  }
  get year() {
    return this.backgroundInformationForm.get('year');
  }
  get month() {
    return this.backgroundInformationForm.get('month');
  }

  async submit() {
    try {
      this.isSubmitting = true;
      const formData = this.backgroundInformationForm.value;
      const res = await this.httpService.httpPutRequest(
        '/employees/save-background-check-data',
        {
          name: `${formData.firstName} ${formData.lastName}`,
          dob: formData.date,
        }
      );
      console.log(res);
      this.router.navigateByUrl('/home');
    } catch (err: any) {
      console.log('EEERRR', err.error.message);
      if (err.error.message) {
        this.popupService.showErrorModal(err.error.message);
      }
    } finally {
      this.isSubmitting = false;
    }
  }
}
