import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ChoosePictureModalComponent } from '../choose-picture-modal/choose-picture-modal.component';
import { CameraSource } from '@capacitor/camera';
import { HttpService } from '../../services/http.service';
import { IEmployee } from '../../interfaces/employee.interface';
import { PopupService } from '../../services/popup.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageService } from '../../services/local-storage.service';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent  implements OnInit, AfterViewChecked {
  @ViewChild('imageContainer') imageContainer!: ElementRef;
  @Input() file!: File;
  @Input() title = '';
  noFile = false;
  cropper!: Cropper;
  rendered = false;
  croppable = false;

  constructor(
    private modalController: ModalController,
    private httpService: HttpService,
    private popupService: PopupService,
    private localStorage: LocalStorageService,
    private loadingCtrl: LoadingController,

  ) {}
  ngAfterViewChecked(){
    if(this.rendered) return;
    if(!this.file){
      this.noFile = true;
      return;
    }
    
    this.renderCropper();
    this.rendered = true;
  }
  ngOnInit() { 
    
  }

  renderCropper(){
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        this.imageContainer.nativeElement.innerHTML = '';
        const img = document.createElement('img');
        img.src = image.src;
        this.imageContainer.nativeElement.appendChild(img);

        // Initialize Cropper
        this.cropper = new Cropper(img, {
          aspectRatio: 1, // Adjust aspect ratio as needed
          viewMode: 1,
          minContainerWidth: 400,
          minContainerHeight: 400,
          minCanvasWidth: 400,
          minCanvasHeight: 400,
          crop: (a) => {
            // Callback function when cropping is done
            console.log(a)
          },
          ready: () => {
            this.croppable = true;
          },
        });
        
      };
    };

    reader.readAsDataURL(this.file);
  }

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

  confirm() {
    if(!this.croppable) return;

    const croppedCanvas = this.cropper.getCroppedCanvas({fillColor: '#fff'});
    croppedCanvas.toBlob((blob) => {
      if(!blob){
        return;
      }
      const file = new File([blob], Date.now() + '.jpeg', {type:"image/jpeg"});
      return this.modalController.dismiss(file, 'confirm');
    }, 'image/jpeg');
  }

}
