import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../modules/shared/services/local-storage.service';
import { NAVS } from './navs';
import { SideMenuService } from 'src/modules/shared/services/side-menu.service';
import { ChooseProfilepictureModalComponent } from 'src/modules/shared/components/profile-picture-modal/profile-picture-modal.component';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  appPages = NAVS;
  showBackBtn = false;
  activeMenu: any;
  public userName = '';
  profilePicture = '';
  constructor(
    private menuController: MenuController,
    private localStorageService: LocalStorageService,
    private router: Router,
    private sideMenuService: SideMenuService,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    this.userName = user.name;
    this.profilePicture = user.profilePicture || '';
  }

  closeMenu() {
    this.menuController.close();
  }

  async navBack() {
    this.showBackBtn = false;
    this.appPages = NAVS;
  }

  async navigate(page: any) {
    console.log(page);
    if (page.path === 'logout') {
      await this.doLogout();
    } else if (page?.subMenu?.length) {
      this.showBackBtn = true;
      this.appPages = page.subMenu;
      this.activeMenu = page;
    } else {
      this.router.navigateByUrl(page.path);
      this.activeMenu = page;
      this.closeMenu();
    }
  }

  async doLogout(): Promise<void> {
    this.menuController.close();
    await this.sideMenuService.logout();
  }

  async showPictureUploadModal() {
    const myModal = await this.modalController.create({
      component: ChooseProfilepictureModalComponent,
      cssClass: 'chooseProfilePictureModal',
      backdropDismiss: true,
    });
    await myModal.present();
    myModal.onDidDismiss().then(async (result) => {
      if (result.data) {
        this.profilePicture = result.data;
        // update profile picture in localStorage
        const user: IUser =
          await this.localStorageService.getLoggedInUserInfo();
        user.profilePicture = result.data;
        await this.localStorageService.setLoggedInUserInfo(user);
      }
    });
  }
}
