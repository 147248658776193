<div>
  <div class="header">
    <div class="icon-cancel">
      <ion-icon
        class="close-icon"
        name="close-outline"
        (click)="closeModal()"
      ></ion-icon>
    </div>
    <h1>Welcome to your credentials management centre</h1>
  </div>
  <div class="organization-logo-container">
    <img [src]="logo" />
  </div>
  <div class="body-container">
    <h2>Hi {{userFirstName}}!</h2>
    <p>
      Here you can upload the credentials required for your role as
      {{ roleName }}. Simply tap on each of the items in the list to complete
      your profile.
    </p>
    <img src="assets/images/home-page-reference.jpg" />
    <p>You must upload everything as soon as possible to avoid delays.</p>
    <ion-button
      class="start-now-btn"
      color="dark"
      (click)="closeModal()"
    >
      <div class="start-now-container">
        <ion-label>Start Now</ion-label>
        <div class="ellipse">
          <ion-icon name="arrow-forward"></ion-icon>
        </div>
      </div>
    </ion-button>
  </div>
</div>
