<div class="date-picker-container">
  <div class="ion-text-center child detail">
    <div class="icon-cancel">
      <ion-icon class="close-icon" name="close-outline" (click)="closeModal()"></ion-icon>
    </div>
    <div class="main-txt">
      <p>Pick a date</p>
    </div>
    <div>
      <ion-datetime locale="en-US" [value]="selectedDate" id="date-picker" presentation="date" [preferWheel]="true" [max]="maxDate" [min]="minDate" (ionChange)="onChangeDate($event)"></ion-datetime>
    </div>
    <hr>
    <div class="buttons-section">
      <!-- <p>Please select a date</p> -->
      <ion-button color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok btn-round" (click)="saveDate()">
        Select
      </ion-button>
    </div>
  </div>
</div>