
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="image-container" #imageContainer></div>
  <div *ngIf="noFile">
    No File Provided..
  </div>
</ion-content>