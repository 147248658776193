import { Component, OnInit, HostListener, Input, Output } from '@angular/core';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { Platform, ModalController } from '@ionic/angular';
import { dataURLtoFile } from 'src/modules/shared/utils/helpers/images.helper';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { CameraSource } from '@capacitor/camera';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'shared-choose-picture-modal',
  templateUrl: './choose-picture-modal.component.html',
  styleUrls: ['./choose-picture-modal.component.scss'],
})
export class ChoosePictureModalComponent implements OnInit {
  selectedFile: ImageSnippet | undefined;
  @Input() selectImage: boolean = false;
  @Input() type!: CameraSource.Camera | CameraSource.Photos;

  public width: number = 250;
  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();

  constructor(
    private platform: Platform,
    private imagesService: ImagesService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.takePhoto(this.type);
  }

  takePhoto(sourceType: CameraSource) {
    this.platform.ready().then(async () => {
      try {
        const filePath = await this.imagesService.choosePicture(sourceType);
        this.modalController.dismiss({ filePath, sourceType });
      } catch (err) {
        this.modalController.dismiss();
        console.log(err);
      }
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
