import { Injectable } from '@angular/core';
import { CredentialActions, ICredential } from 'src/modules/credentials/interfaces/credential.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {

  selectedCredential?: ICredential;
  selectedFilesToUpload?: File[];
  uploadingAction?: CredentialActions;
  employeeCredentialIdToUpdate?: number;

  constructor() {}
}
