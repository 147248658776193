<div class="slide-section" *ngIf="uploadedCredentials.length">
    <div class="slides">
      <swiper-container init="false" #swiper>
        <swiper-slide *ngFor="let item of uploadedCredentials; let i = index">

          <div class="fixed-container" *ngIf="item.fileType === 'image'">
            <img class="object-fit-cover" src={{item.filepath}}>

            <div *ngIf="isRejectedCredential(item)" class="reupload">
              <!-- <i class="fa fa-times"></i> -->
              <ion-icon name="close" class="close-icon"></ion-icon>
              <span>Not approved</span>
              <ion-button (click)="addCertificates('reupload', item)" class="retake-photo">Re-upload</ion-button>
              <!-- <i (click)="showReasonOfRejection(item)" class="fa fa-info-circle" aria-hidden="true"></i> -->
              <ion-icon (click)="showReasonOfRejection(item)" name="help-circle" class="info-icon"></ion-icon>

            </div>

            <p *ngIf="enableExpiryTitle(item)" class="cert-action expired">
              Credential is expired
            </p>

            <div class="actions">
              <p *ngIf="enableDeleteButton(item)" class="cert-action" (click)="showDeleteCertificatePopup(item)">
                <!-- <i class="fa fa-trash"></i> -->
                <ion-icon name="trash" class="trash-icon"></ion-icon>
                Delete
              </p>
              <p *ngIf="enableRetakeButton(item)" class="cert-action" (click)="moveToRetakeScreen(item)">
                Re-upload
              </p>
            </div>
          </div>

          <div *ngIf="item.fileType === 'pdf'" class="full-width">
            <div class="file-box">
              PDF File <br>
              <a class="view-certificate-link" (click)="openLink(item.filepath)">View Credential File</a>
            </div>

            <div *ngIf="isRejectedCredential(item)" class="reupload">
              <!-- <i class="fa fa-times"></i> -->
              <ion-icon name="close" class="close-icon"></ion-icon>
              <span>Not approved</span>
              <ion-button (click)="addCertificates('reupload', item)" class="retake-photo">Re-upload</ion-button>
              <!-- <i (click)="showReasonOfRejection(item)" class="fa fa-info-circle" aria-hidden="true"></i> -->
              <ion-icon (click)="showReasonOfRejection(item)" name="help-circle" class="info-icon"></ion-icon>
            </div>

            <div class="actions">
              <p *ngIf="enableDeleteButton(item)" class="cert-action" (click)="showDeleteCertificatePopup(item)">
                <!-- <i class="fa fa-trash"></i> -->
                <ion-icon name="trash" class="trash-icon"></ion-icon>
                Delete
              </p>
              <p *ngIf="enableRetakeButton(item)" class="cert-action" (click)="moveToRetakeScreen(item)">
                Re-upload
              </p>
            </div>
          </div>

          <div *ngIf="item.fileType === 'doc'" class="full-width">
            <div class="box">
              <p class="document-txt">Document File encounterd. Please <a (click)="openLink(item.filepath)">Click
                  here</a> to
                view credential.</p>
            </div>

            <div *ngIf="isRejectedCredential(item)" class="reupload">
              <!-- <i class="fa fa-times"></i> -->
              <ion-icon name="close" class="close-icon"></ion-icon>
              <span>Not approved</span>
              <ion-button (click)="addCertificates('reupload', item)" class="retake-photo">Re-upload</ion-button>
              <!-- <i (click)="showReasonOfRejection(item)" class="fa fa-info-circle" aria-hidden="true"></i> -->
              <ion-icon (click)="showReasonOfRejection(item)" name="help-circle" class="info-icon"></ion-icon>
            </div>

            <div class="actions">
              <p *ngIf="enableDeleteButton(item)" class="cert-action" (click)="showDeleteCertificatePopup(item)">
                <!-- <i class="fa fa-trash"></i> -->
                <ion-icon name="trash" class="trash-icon"></ion-icon>
                Delete
              </p>
              <p *ngIf="enableRetakeButton(item)" class="cert-action" (click)="moveToRetakeScreen(item)">
                Re-upload
              </p>
            </div>
          </div>

        </swiper-slide>

      </swiper-container>
    </div>
    <div class="add-more">
      
      <ion-button color="dark" (click)="addCertificates('add')">
        <!-- <i class="fas fa-plus"></i> -->
        <ion-icon name="add" class="plus-icon"></ion-icon>
      </ion-button>
    </div>
</div>