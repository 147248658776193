import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

function parseDateString(dateString?: string): Date | null {
  const [day, month, year] = dateString?.split('/').map(Number) || [];
  if (!day || !month || !year) {
    return null;
  }
  return new Date(year, month - 1, day);
}

export function dateRangeValidator(startDateKey: string, endDateKey: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startDateControl = formGroup.get(startDateKey);
    const endDateControl = formGroup.get(endDateKey);

    const startDate = parseDateString(startDateControl?.value);
    const endDate = parseDateString(endDateControl?.value);

    if (startDate && endDate && startDate >= endDate) {
      startDateControl?.setErrors({ dateRange: 'Start date cannot be later than end date' });
      endDateControl?.setErrors({ dateRange: 'Start date cannot be later than end date' });
      return { dateRange: 'Start date cannot be later than end date' };
    }

    if (startDateControl?.hasError('dateRange')) {
      startDateControl.setErrors(null);
    }
    if (endDateControl?.hasError('dateRange')) {
      endDateControl.setErrors(null);
    }

    return null;
  };
}