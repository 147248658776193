import { Component, NgModule, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { HttpService } from '../../shared/services/http.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { IUser } from '../interfaces/user.interface';
import { PushWooshService } from 'src/modules/shared/services/pushWoosh.service';
import { AlertController } from '@ionic/angular';
import { PublicSettings, SettingKeys } from 'src/modules/shared/interfaces/settings.interface';
import { ThemeService } from 'src/modules/shared/services/theme.service';
// import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})


export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public isSubmitting: boolean = false;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private pushWooshService: PushWooshService,
    private alertController: AlertController,
    private themeService: ThemeService,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(4)])
    });
  }

  async processForm() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value)
      this.isSubmitting = true;
      try {
        const res: IUser = await this.httpService.httpPostRequest('/auth/employee/login', this.loginForm.value)
        console.log(res);
        await this.localStorageService.setLoggedInUserInfo(res);
        await this.localStorageService.setLoggedInUserToken(res.accessToken);
        await this.setupDeviceToken();
        this.isSubmitting = false;
        this.router.navigateByUrl(res.isTempPasswordReset ? '/home' : '/reset-password', {replaceUrl: true});
      } catch (err: any) {
        this.isSubmitting = false;
        await this.showErrorPopup('Error', err?.error?.message || err?.statusText);
        console.log(err);
      }
    }
  }

  async setupDeviceToken(): Promise<void> {
    const deviceToken = await this.pushWooshService.registerDevice();
    if (deviceToken) {
      await this.localStorageService.setDeviceToken(deviceToken);
      await this.httpService.httpPostRequest('/device-token', { deviceToken: deviceToken });
    }
  }

  async showErrorPopup(title: string, message: string) {
    const alert = await this.alertController.create({
      header: title,
      message
    });
    await alert.present();
  }

  get isEmailValid() {
    return this.loginForm.controls['email'].value?.length && this.loginForm.controls['email'].valid;
  }

  get isPassValid() {
    return this.loginForm.controls['password'].value?.length && this.loginForm.controls['password'].valid;
  }

  forgotPass() {
    this.router.navigateByUrl('/forgot-password');
  }

  resetPass() {
    this.loginForm.controls['password'].reset();
  }

  async ngOnInit() {
    this.themeService.setTheme();
  }

}
