<div class="cred-box">
  <div
    class="details"
    (click)="
      !credentialStatus
        ? goToUploadScreen(credential)
        : goToUploadedScreen(credential)
    "
  >
    <!-- <ion-icon name="ellipse"></ion-icon> -->
    {{ credential.name }}
  </div>
  <div class="actions">
    <ng-container [ngSwitch]="credentialStatus">
      <div class="rejected" *ngSwitchCase="'rejected'">
        <button
          class="btn btn-rounded"
          (click)="showReuploadReasonModal(credential)"
        >
          Re-upload
        </button>
        <ion-icon name="close"></ion-icon>
      </div>
      <div class="expired" *ngSwitchCase="'expired'">
        <button
          class="btn btn-rounded"
          (click)="showExpiredCredentialModal(credential)"
        >
          Expired
        </button>
        <ion-icon name="close"></ion-icon>
      </div>
      <div
        class="pending"
        (click)="goToUploadedScreen(credential)"
        *ngSwitchCase="'pending'"
      >
        <ion-icon name="time-outline"></ion-icon>
      </div>
      <div
        class="missing"
        (click)="goToUploadScreen(credential)"
        *ngSwitchDefault
      >
        <ion-icon class="whole-icon" name="arrow-forward-circle"></ion-icon>
      </div>
    </ng-container>
  </div>
</div>
