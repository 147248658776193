<div class="main-container">
  <div class="upper-section">
    <div class="head-section">
      <div class="head-and-detail">
        <h1 class="common-head certificate-heading main-heading">
          {{ title }}
          <span>
            <ion-icon name="help-circle" class="question-icon" (click)="showInstruction()"></ion-icon>
          </span>
        </h1>
      </div>
      <div class="underline"></div>
      <p class="note">{{ detail }}</p>
    </div>

    <div class="slide-section">
      <div class="slides">
        
        <swiper-container init="false" #swiper>
          <swiper-slide *ngFor="let image of sliderImages; let i = index">

            <div class="fixed-container">
              <img class="object-fit-cover" *ngIf="image.type === 'image'" [src]="image.url">

              <div class="file-box" *ngIf="image.type !== 'image'">
                {{ image.type.toUpperCase() }} File <br>({{ image.name }})
              </div>
      
              <div class="actions">
                <p class="cert-action" (click)="handleDelete(i)">
                  <!-- <i class="fa fa-trash"></i> -->
                  <ion-icon name="trash" class="trash-icon"></ion-icon>
                  Delete
                </p>
                <p class="cert-action" (click)="doRetake(i)">
                  Retake
                </p>
              </div>
            </div>

          </swiper-slide>
        </swiper-container>
      </div>

      <div class="add-more" *ngIf="credentialAction === 'add'">
        <ion-button color="dark" (click)="doAddMore()">
          <!-- <i class="fas fa-plus"></i> -->
          <ion-icon name="add" class="plus-icon"></ion-icon>
        </ion-button>
      </div>
    </div>

    <div class="main-actions">
      <ion-button color="dark" (click)="submit()" shape="round">
        Save
      </ion-button>
    </div>

  </div>
</div>