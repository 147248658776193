import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'isHtml'
})
export class IsHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any): any {
        return /<[a-z][\s\S]*>/i.test(value)
    }
}
