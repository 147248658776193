
<div class = "main-container apply-padding" >
  <div class = "upper-section" > 
    <div class = "head-section">
      <h1 class = "main-heading">References<div class = "icon-div"><i class = "fa fa-question-circle"></i></div></h1>
      <div class = "underline"></div>
      <div class = "sub-heading">
        Please add 3 references
      </div>
      <div>
        <div class = "form-section">
          <form [formGroup]="informationForm" *ngIf="informationForm" (submit) = "submitForm()">
            <div class = "form-initalize reference-card" *ngFor="let cd of referenceCollection ;let i=index;">
              <div [formGroup]="cd">
                <h6 class="ion-no-margin"><b>Your Information:</b></h6>
                <div class = "form-row">
                  <div class = "field-column">
                    <div class = "date-head">
                      <ion-input  type="text" formControlName="company" placeholder="Company">
                      </ion-input>

                      <p *ngIf="cd.controls['company'].invalid && (cd.controls['company'].dirty || cd.controls['company'].touched)" class="err-msg">Invalid Company Name</p>
                    </div>
                    
                    <div class = "date-head" > 
                      <ion-input type="text" formControlName="role"
                        placeholder="Role">
                      </ion-input>

                      <p *ngIf="cd.controls['role'].invalid && (cd.controls['role'].dirty || cd.controls['role'].touched)" class="err-msg">Invalid Role</p>
                    </div>

                  </div>  
                </div>
                <div class = "form-row">
                  <div class = "field-column">
                    <div class = "date-head">
                      <ion-item lines="none">
                        <ion-input (click)="showDatePicker(cd, 'startDate', 0)" formControlName="startDate" placeholder="Start Date"></ion-input>
                        <ion-icon  name="calendar-number-outline"></ion-icon>
                      </ion-item>
                      <p *ngIf="cd.controls['startDate'].invalid && (cd.controls['startDate'].dirty || cd.controls['startDate'].touched)" class="err-msg">
                        <span *ngIf="cd.controls['startDate'].hasError('required')">Start Date is required</span>
                        <span *ngIf="cd.controls['startDate'].hasError('dateRange')">Start Date cannot be later than End Date</span>
                        <span *ngIf="cd.controls['startDate'].hasError('pattern')">Invalid Start Date format</span>
                      </p>
                    </div>

                    <div class="date-head">
                      <ion-item lines="none">
                        <ion-input (click)="showDatePicker(cd, 'endDate', 0)" formControlName="endDate" placeholder="End Date"></ion-input>
                        <ion-icon  name="calendar-number-outline"></ion-icon>
                      </ion-item>
                      <p *ngIf="cd.controls['endDate'].invalid && (cd.controls['endDate'].dirty || cd.controls['endDate'].touched)" class="err-msg">
                        <span *ngIf="cd.controls['endDate'].hasError('required')">End Date is required</span>
                        <span *ngIf="cd.controls['endDate'].hasError('dateRange')">End date cannot be earlier than the start date</span>
                        <span *ngIf="cd.controls['endDate'].hasError('pattern')">Invalid End Date format</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class = "form-row">
                  <div class = "field-column"></div>
                  <div class="date-head">
                    <ion-item lines="none" class="checkbox-input-item">
                      <ion-checkbox formControlName="isPresent" labelPlacement="end" justify="start">Present</ion-checkbox>
                    </ion-item>
                  </div>
                </div>
                <h6 class="ion-no-margin"><b>Your Reference Information:</b></h6>
                <div class = "form-row">
                  <div class = "field-column">
                    <div class = "date-head">
                      <ion-input  type="text" formControlName="referenceName" placeholder="Name">
                      </ion-input>

                      <p *ngIf="cd.controls['referenceName'].invalid && (cd.controls['referenceName'].dirty || cd.controls['referenceName'].touched)" class="err-msg">Invalid Reference Name</p>
                    </div>
                    
                    <div class = "date-head" > 
                      <ion-input type="text" formControlName="referenceRole"
                        placeholder="Reference role">
                      </ion-input>

                      <p *ngIf="cd.controls['referenceRole'].invalid && (cd.controls['referenceRole'].dirty || cd.controls['referenceRole'].touched)" class="err-msg">Invalid Reference Role</p>
                    </div>

                  </div>  
                </div>
                <div class = "form-row">
                  <div class = "field-column">
                    <div class = "date-head">
                      <ion-input  type="text" formControlName="referencePhoneNumber" placeholder="Phone">
                      </ion-input>

                      <p *ngIf="cd.controls['referencePhoneNumber'].invalid && (cd.controls['referencePhoneNumber'].dirty || cd.controls['referencePhoneNumber'].touched)" class="err-msg">Invalid Phone Number</p>
                    </div>
                    
                    <div class = "date-head" > 
                      <ion-input type="text" formControlName="referenceEmail"
                        placeholder="Email">
                      </ion-input>

                      <p *ngIf="cd.controls['referenceEmail'].invalid && (cd.controls['referenceEmail'].dirty || cd.controls['referenceEmail'].touched)" class="err-msg">Invalid Email Address</p>
                    </div>

                  </div>  
                </div>
              </div>
            </div>
            <div class = "lower-section">
              <div class = "remaining-div">
                <div class="submit-wrapper">
                  <button [disabled]="isSubmitting">
                    Confirm
                    <ion-spinner name="lines-sharp-small" *ngIf="isSubmitting"></ion-spinner>
                  </button>
                </div>
              </div>
              <div class = "extra-spacing"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

