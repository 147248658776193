export const NAVS = [
    {
        title: 'Home',
        icon: 'home',
        path: 'home',
        forApprovedUserOnly: false,
        expand: false,
    },
    // {
    //     title: 'Shifts',
    //     icon: 'copy',
    //     path: 'shifts',
    //     expand: true,
    //     forApprovedUserOnly: true,

    //     subMenu: [
    //         {
    //             title: 'Available shifts',
    //             icon: 'briefcase',
    //             path: 'talent-home',
    //             forApprovedUserOnly: true
    //         },
    //         {
    //             title: 'Upcoming shifts',
    //             icon: 'arrow-up',
    //             path: 'upcoming-shifts',
    //             forApprovedUserOnly: true
    //         }, {
    //             title: 'Confirm your shifts',
    //             icon: 'checkbox',
    //             path: 'shifts-confirmation',
    //             forApprovedUserOnly: true
    //         },
    //         {
    //             title: 'My Applications',
    //             icon: 'mail',
    //             path: 'my-applications',
    //             forApprovedUserOnly: true
    //         },
    //     ]
    // },
    {
        title: 'Logout',
        icon: 'log-out',
        path: 'logout',
        forApprovedUserOnly: false
    },
];