import { Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { RegexService } from 'src/modules/shared/services/regex.service';
import { HttpService } from '../../shared/services/http.service';
import { Router } from '@angular/router';
import { IReference } from '../interfaces/reference.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { dateRangeValidator } from './date-range-validator/date-range-validator';

@Component({
  selector: 'app-add-reference-form',
  templateUrl: './add-reference-form.component.html',
  styleUrls: ['./add-reference-form.component.scss'],
})
export class AddReferenceFormComponent implements OnInit {

  informationForm!: any;
  referenceCollection: FormGroup[] = [];
  totalReferences: number = 3;
  selectedDate!: any;
  userId!: number;
  isSubmitting = false;
  constructor(
    private popupService: PopupService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private regexService: RegexService,
    private localStorageService: LocalStorageService,
    private httpService: HttpService,
    private router: Router,
  ){
  }
  
  async ngOnInit() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    this.userId = user.id;
    this.createForm();
    this.fetchReferences()
  }

  dateParser(date: string & number & Date){
    return moment(date).format('DD/MM/YYYY');
  }

  async fetchReferences(){
    try {
      const references: IReference[] = await this.httpService.httpGetRequest(`/references/${this.userId}`);
      if (references?.length) {
        // Loop through references and populate the form controls with their values
        references.forEach((reference, index) => {
          const referenceFormGroup = this.informationForm.get('referenceDetails')['controls'][index];
      
          referenceFormGroup.patchValue({
            id: reference.id,
            company: reference.company,
            role: reference.role,
            startDate: this.dateParser(reference.startDate),
            endDate: reference.isPresent
              ? null
              : this.dateParser(reference.endDate),
            isPresent: reference.isPresent,
            referenceName: reference.referenceName,
            referenceRole: reference.referenceRole,
            referenceEmail: reference.referenceEmail,
            referencePhoneNumber: reference.referencePhoneNumber,
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async showDatePicker(cd: any, type: string, i: number) {
      //set initial date to display on date picker modal
    let initialDate = cd.value.startDate;
    
    if(type === 'endDate')
      initialDate = cd.value.endDate;

    const modalData = await this.popupService.showDatePicker(initialDate);
    if (modalData.role === 'success' && modalData.data.selectedDate) {
      const date = moment(modalData.data.selectedDate).format('DD/MM/YYYY');
      cd.controls[type].patchValue(date || '');
    }
  }

  createForm() {
    this.referenceCollection = [];
    for(let i = 0 ; i < this.totalReferences ; i++) {
      this.referenceCollection.push(this.BuildFormDynamic(i));
    }

    this.informationForm = this.formBuilder.group({
      referenceDetails: this.formBuilder.array(this.referenceCollection)
    })

  }

  BuildFormDynamic(index: number) {

    let initializeReference = {
      id: new FormControl(),
      company: new FormControl('', [Validators.required, Validators.pattern(this.regexService.companyRegex())]),
      role: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      isPresent: new FormControl(false),
      referenceName: new FormControl('', [Validators.required]),
      referenceRole: new FormControl('', [Validators.required]),
      referenceEmail: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/)]),
      referencePhoneNumber: new FormControl('', [Validators.required, Validators.minLength(10) , Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]),

      employeeId: new FormControl(this.userId),
      organizationId: new FormControl(),
    };
    const fg = this.formBuilder.group(initializeReference, { validators: dateRangeValidator('startDate', 'endDate') });

    fg.controls['isPresent'].valueChanges.subscribe(value => {
      if(value){
        fg.controls['endDate'].clearValidators();
        fg.controls['endDate'].setValue('');
        fg.controls['endDate'].disable();
      } else{
        fg.controls['endDate'].addValidators(Validators.required);
        fg.controls['endDate'].enable();
      }
      fg.controls['endDate'].updateValueAndValidity()
      console.log(fg.controls['endDate'].errors)
    })
    return fg
  }


  async submitForm(){
    this.informationForm.markAllAsTouched();
    if (this.informationForm.valid) {
      this.transformReferenceDatesToISO();
      this.isSubmitting = true;
      try {
        await this.httpService.httpPostRequest('/references/multiple', this.informationForm.value)
        this.isSubmitting = false;
        this.transformISOToReferenceDates();
        this.router.navigateByUrl( '/home');
      } catch (err) {
        this.transformISOToReferenceDates();
        this.isSubmitting = false;
        console.log(err);
      }
    }
  }

  transformReferenceDatesToISO(): void {
    this.informationForm.value.referenceDetails.forEach((reference: IReference, index: number) => {
      const referenceFormGroup = this.informationForm.get('referenceDetails')['controls'][index];
      referenceFormGroup.patchValue({
        startDate: moment(reference.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
        endDate: reference.isPresent ? null : moment(reference.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
      });
    });
  }

  transformISOToReferenceDates(): void {
    this.informationForm.value.referenceDetails.forEach((reference: IReference, index: number) => {
      const referenceFormGroup = this.informationForm.get('referenceDetails')['controls'][index];
      referenceFormGroup.patchValue({
        startDate: moment(reference.startDate, 'MM/DD/YYYY').format('DD/MM/YYYY'),
        endDate: reference.isPresent ? null : moment(reference.endDate, 'MM/DD/YYYY').format('DD/MM/YYYY'),
      });
    });
  }

}
