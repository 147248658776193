import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ChoosePictureModalComponent } from '../choose-picture-modal/choose-picture-modal.component';
import { CameraSource } from '@capacitor/camera';
import { HttpService } from '../../services/http.service';
import { IEmployee } from '../../interfaces/employee.interface';
import { PopupService } from '../../services/popup.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageService } from '../../services/local-storage.service';
import { ImageCropperModalComponent } from '../image-cropper-modal/image-cropper-modal.component';

@Component({
  selector: 'app-profile-picture-modal',
  templateUrl: './profile-picture-modal.component.html',
  styleUrls: ['./profile-picture-modal.component.scss'],
})
export class ChooseProfilepictureModalComponent  implements OnInit {
  @ViewChild('imageContainer') imageContainer!: ElementRef;
  user?: IUser;
  fileUploadLoader?: any;

  constructor(
    private modalController: ModalController,
    private httpService: HttpService,
    private popupService: PopupService,
    private localStorage: LocalStorageService,
    private loadingCtrl: LoadingController,

  ) {}

  ngOnInit() { }

  async showUploadLoader() {
    this.fileUploadLoader = await this.loadingCtrl.create({
      message: 'Please wait',
    });
    this.fileUploadLoader.present();
  }

  hideUploadLoader() {
    this.fileUploadLoader?.dismiss();
  }

  async showModal(category: string) {
    const myModal = await this.modalController.create({
      component: ChoosePictureModalComponent,
      cssClass: 'uploadImgModal',
      componentProps: {
        type: category === 'files' ? CameraSource.Photos : CameraSource.Camera
      }
    });

    myModal.onDidDismiss().then(async (dataReturned) => {
      if (dataReturned?.data) {
        if (dataReturned.data.filePath) {
          let blob = await fetch(dataReturned.data.filePath.webPath).then(r => r.blob());
          const file = new File([blob], Date.now() + '.jpeg', {type:"image/jpeg"});
          
          const cropModal = await this.modalController.create({
            component: ImageCropperModalComponent,
            cssClass: 'image-cropper',
            componentProps: {
              title: 'Profile Picture',
              file
            }
          });
          cropModal.onDidDismiss()
            .then((result) => {
              if(!result.data) return;
              this.submit(result.data);
              console.log(result.data)
            })
          cropModal.present();
        } else {
          dataReturned.data.fd.forEach((file: File) => {
            const renamedFile = new File([file], Date.now() + '.jpeg', {type:"image/jpeg"});
            this.submit(renamedFile);
          })
        }
      }
    });
    return await myModal.present();
  }

  async submit(fileToUpload: File) {
    try {
      this.showUploadLoader();
      const formData = new FormData();
      formData.append('profilePicture', fileToUpload);
      // sending API request
      const url = `/auth/employee/profile-picture`;
      const res = await this.httpService.httpPatchRequest<IEmployee>(url, formData);
      
      if (res.profilePicture) {
        this.modalController.dismiss(res.profilePicture);
        this.showPopup('Success', 'Profile picture updated successfully.', null);
      }
    } catch (error: any) {
      console.log('Error while uploading file ... ', error);
      this.popupService.showErrorModal(error?.error?.message || 'Something went wrong');
    } finally {
      this.hideUploadLoader();
    }
  }

  showPopup(heading: string, message: string, imgURL: string | null) {
    this.popupService.showModal({
      heading,
      message,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL
    });
  }

}
