import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {
  @Input() user!: IUser;
  public roleName: string = '{Role Name}';
  public userFirstName: string = '';
  public logo: string = '';
  constructor(private modalController: ModalController) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.userFirstName = this.user?.name?.split(' ')?.[0] || '';
    this.roleName = this.user?.roles?.[0].name || '';
    this.logo = this.user?.organization?.logo || 'assets/images/login-logo.png';
  }
}
