import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../shared/services/local-storage.service';
import {
  catchError,
  combineLatestAll,
  combineLatestWith,
  filter,
  map,
  startWith,
  switchMap,
  tap,
  zipAll,
} from 'rxjs/operators';
import { Subscription, forkJoin, of, throwError } from 'rxjs';
import { HttpService } from '../shared/services/http.service';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from '../shared/services/header.service';
import { ICredential } from '../credentials/interfaces/credential.interface';
import {
  BackgroundCheckStatus,
  IUser,
} from '../authentication/interfaces/user.interface';
import { PopupService } from '../shared/services/popup.service';
import { UploadedCredentials } from '../credentials/interfaces/employeeCredential.interface';
import { Platform } from '@ionic/angular';
import {
  IRole,
  IRoleCredentialSetting,
} from '../shared/interfaces/role.interface';
import { SideMenuService } from '../shared/services/side-menu.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IReference } from '../credentials/interfaces/reference.interface';
import { HelperService } from '../shared/services/helper.service';

type CredentialGroupStatuses = {
  [key: string]: 'pending' | 'approved' | 'rejected' | 'expired';
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user?: IUser;
  roleName?: string;
  moreRoles?: string;
  location?: string;
  criticalCredentials: ICredential[] = [];
  nonCriticalCredentials: ICredential[] = [];
  approvedCredentials: string[] = [];
  status = 'pending';
  uploadedCredentials: UploadedCredentials = {};
  uploadedCredentialStatuses: CredentialGroupStatuses = {};
  router$?: Subscription;
  isReferenceRequired = false;
  appResumer$?: Subscription;
  referencesSubmited = false;

  backgroundCheckStatus: BackgroundCheckStatus =
    BackgroundCheckStatus.NOT_REQUIRED;
  isLoading: boolean = false;
  isSubmittingForApproval: boolean = false;
  disableSubmitingForApproval = true;
  submitingForApprovalBtnText = 'Submit for approval';

  constructor(
    private localStorageService: LocalStorageService,
    private httpService: HttpService,
    private router: Router,
    private headerService: HeaderService,
    private popupService: PopupService,
    public platform: Platform,
    private sideMenuService: SideMenuService,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {
    this.router$ = this.router.events
      .pipe(
        filter(
          (event) => event instanceof NavigationEnd && event.url === '/home'
        ),
        startWith({ url: this.router.url }),
        switchMap(() => {
          this.isLoading = true;
          return this.fetchUser();
        }),
        switchMap((user) => {
          this.user = user;
          return this.fetchReferences(user);
        }),
        switchMap((references) => {
          if (references?.length == 3) {
            this.referencesSubmited = true;
          }
          return this.fetchEmployeeCredentials();
        }),
        map((employeeCreds) => ({ employeeCreds, user: this.user })),
        catchError((error: HttpErrorResponse) => {
          this.isLoading = false;
          console.log('error while fetching uploaded credentials ... ', error);
          if (error.error.statusCode === 401) {
            this.sideMenuService.logout();
          }
          return throwError(() => error);
        })
      )
      .subscribe(({ employeeCreds: uploadedCredentials, user }) => {
        if (user) {
          this.setUserData(user);
          this.mapUploadedCredentials(uploadedCredentials);
          this.isLoading = false;
          this.showWelcomePopup(user);
        }
      });

    // Refresh app on comming to foreground from background
    this.appResumer$ = this.platform.resume.subscribe(() => {
      this.doRefresh();
    });
  }

  async fetchEmployeeCredentials(): Promise<UploadedCredentials> {
    return this.httpService.httpGetRequest<UploadedCredentials>(
      '/employee-credentials'
    );
  }

  async fetchReferences(user: IUser): Promise<IReference[]> {
    return this.httpService.httpGetRequest<IReference[]>(
      `/references/${user.id}`
    );
  }

  async fetchUser(): Promise<IUser> {
    return this.httpService.httpGetRequest<IUser>('/auth/employee/me');
  }

  mapUploadedCredentials(uploadedCredentials: UploadedCredentials): void {
    this.uploadedCredentials = uploadedCredentials;

    // Checking if every ciritical cred is uploaded &
    // Checking if submitting first time into server
    this.setSubmitForApprovalBtnVisibility();

    // Getting status for cred groups are they missing, pending or rejected
    this.uploadedCredentialStatuses = this.getCredentialGroupStatus(
      this.uploadedCredentials
    );

    // Getting approved credentials
    this.approvedCredentials = Object.entries(this.uploadedCredentialStatuses)
      .filter(([cred, status]) => status === 'approved')
      .map(([cred]) => cred);

    // Getting count for urgent credentials for header
    const urgentCredentialsCount = this.criticalCredentials.filter((c) => {
      const credStatus = this.uploadedCredentialStatuses[c.name];
      return (
        !credStatus || credStatus === 'rejected' || credStatus === 'expired'
      );
    }).length;
    this.headerService.urgentCredentialsCount.next(urgentCredentialsCount);
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
    this.appResumer$?.unsubscribe();
  }

  goToReferencesScreen() {
    this.router.navigateByUrl('/credentials/references', {
      state: {},
    });
  }

  getCredentialGroupStatus(
    uploadedCredentials: UploadedCredentials
  ): CredentialGroupStatuses {
    return Object.entries(uploadedCredentials).reduce(
      (acc: CredentialGroupStatuses, [credName, credArray]) => {
        if (credArray.every((c) => c.status === 'pending')) {
          acc[credName] = 'pending';
        } else if (credArray.every((c) => c.status === 'approved')) {
          acc[credName] = 'approved';
        }

        if (credArray.some((c) => c.status === 'rejected')) {
          acc[credName] = 'rejected';
        }

        const certificateWithMaxExpiry = credArray.sort(
          (credA, credB) =>
            Date.parse(credB.expiryDate || '') -
            Date.parse(credA.expiryDate || '')
        )[0];
        if (
          Date.parse(certificateWithMaxExpiry.expiryDate || '') < Date.now()
        ) {
          acc[credName] = 'expired';
        }

        return acc;
      },
      {}
    );
  }

  async submitCredentials() {
    this.isSubmittingForApproval = true;
    try {
      await this.httpService.httpPutRequest(
        '/employees/submit-for-approval',
        {}
      );
      // Updating user so we can have submitForApproval boolean
      const user = await this.fetchUser();
      await this.setUserData(user);
      // Updating submit for approval btn visibility
      this.setSubmitForApprovalBtnVisibility();
      // show popup
      this.popupService.showSubmitForReviewModal();
    } catch (err: any) {
      console.log(err.error.message);
      if (err.error.message) {
        this.popupService.showErrorModal(err.error.message);
      }
    } finally {
      this.isSubmittingForApproval = false;
    }
  }

  async doRefresh(event?: any): Promise<void> {
    try {
      const employeeCredentials = await this.fetchEmployeeCredentials();
      this.headerService.showActionRequiredCTA();
      this.mapUploadedCredentials(employeeCredentials);
    } catch (error) {
      console.log(error);
    } finally {
      if (event) {
        event.target.complete();
      }
    }
  }

  async setUserData(user: IUser) {
    this.user = user;
    if (!user.roles?.length) {
      this.isLoading = false;
      return;
    }
    this.roleName = this.helperService.transformRoleNameToCapitalize(user.roles[0]?.name) || 'Unknown';
    this.moreRoles =
      user.roles.length > 1 ? ` and ${user.roles?.length - 1} more` : '';

    // Separating role credential settings
    const roleCredentialSettingsMap: {
      [credentialId: number]: IRoleCredentialSetting;
    } = {};
    for (const role of user.roles) {
      for (const item of role.roleCredentialSettings) {
        if (!roleCredentialSettingsMap[item.credentialId]) {
          // getting only unique
          roleCredentialSettingsMap[item.credentialId] = item;
        } else if (
          !roleCredentialSettingsMap[item.credentialId].isCredentialCritical &&
          item.isCredentialCritical
        ) {
          // marking credential critical if it is critical in any role
          roleCredentialSettingsMap[item.credentialId].isCredentialCritical =
            true;
        }
      }
    }

    this.criticalCredentials = [];
    this.nonCriticalCredentials = [];
    for (const credentialId in roleCredentialSettingsMap) {
      if (roleCredentialSettingsMap[credentialId].isCredentialCritical) {
        this.criticalCredentials.push(
          roleCredentialSettingsMap[credentialId].credential
        );
      } else {
        this.nonCriticalCredentials.push(
          roleCredentialSettingsMap[credentialId].credential
        );
      }
    }

    this.isReferenceRequired = user.roles.some(
      (role: IRole) => role.referencesRequired
    );
    this.location = user.location?.street || 'Unknown';
    this.backgroundCheckStatus = user.backgroundCheckStatus;
    // Setting user into local storage
    this.localStorageService.setLoggedInUserInfo(user);
    console.log('ASDASDASDASDAS', this.nonCriticalCredentials);
  }

  showNoCriticalCreadentialRequired() {
    if (this.backgroundCheckStatus === BackgroundCheckStatus.REQUIRED) {
      return false;
    }
    if (this.isReferenceRequired) {
      return false;
    }
    for (const item of this.criticalCredentials) {
      if (this.uploadedCredentialStatuses[item.name] !== 'approved') {
        return false;
      }
    }
    return true;
  }

  setSubmitForApprovalBtnVisibility(): void {
    const isCriticalCredsUploaded =
      this.criticalCredentials.every((cred) => {
        return this.uploadedCredentials[cred.name];
      }) && (this.isReferenceRequired ? this.referencesSubmited : true);
    const isAllCredsUploaded =
      this.nonCriticalCredentials.every((cred) => {
        return this.uploadedCredentials[cred.name];
      }) && isCriticalCredsUploaded;

    this.submitingForApprovalBtnText =
      isAllCredsUploaded && !!this.user?.submitForApproval
        ? 'Waiting for approval'
        : 'Submit for approval';
    this.disableSubmitingForApproval =
      !isCriticalCredsUploaded || !!this.user?.submitForApproval;
  }

  noNonCriticalCredRequired(): boolean {
    if (!this.nonCriticalCredentials?.length) {
      return false;
    }

    for (const item of this.nonCriticalCredentials) {
      if (this.uploadedCredentialStatuses[item.name] !== 'approved') {
        return true;
      }
    }

    return false;
  }

  async showWelcomePopup(user: IUser) {
    if (user.intro?.welcome) return;

    await this.popupService.showWelcomeModal(user);
    const intro = user.intro || {};
    this.httpService.httpPutRequest(`/auth/employee/profile`, {
      intro: {
        ...intro,
        welcome: true,
      },
    });
  }
}
