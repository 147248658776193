import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { WebcamModule } from 'ngx-webcam';



// Services
import { HttpService } from './services/http.service';
import { LocalStorageService } from './services/local-storage.service';
import { PopupService } from './services/popup.service';
import { ImagesService } from './services/images.service';
import { AddressModificationService } from './services/address-modification.service';
import { HelperService } from './services/helper.service';

// Pipes
import { ReplaceLineBreaks } from './pipes/line-break.pipe';
import { IsHtmlPipe } from './pipes/is-html-string.pipe';

// Components
import { SuccessPopupComponent } from './components/success-popup/success-popup.component';
import { ChoosePictureModalComponent } from './components/choose-picture-modal/choose-picture-modal.component';
import { SetExpiryModalComponent } from './components/set-expiry-modal/set-expiry-modal.component';
import { ViewCredentialsComponent } from './components/view-credentials/view-credentials.component';
import { CredentialInstructionModalComponent } from './components/credential-instruction-modal/credential-instruction-modal.component';
import { DisapproveReasonModalComponent } from './components/disapprove-reason-modal/disapprove-reason-modal.component';
import { RegexService } from './services/regex.service';
import { DatePickerModalComponent } from './components/date-picker-modal/date-picker-modal.component';
import { FormsModule } from '@angular/forms';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NotificationTabComponent } from './components/notification-tab/notification-tab.component';
import { PushWooshService } from './services/pushWoosh.service';
import { CredentialBarComponent } from './components/credential-bar/credential-bar.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { ChooseProfilepictureModalComponent } from './components/profile-picture-modal/profile-picture-modal.component';
import { ImageCropperModalComponent } from './components/image-cropper-modal/image-cropper-modal.component';
import { ThemeService } from './services/theme.service';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        IonicModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        WebcamModule,
        FormsModule
    ],
    providers: [
        HttpService,
        LocalStorageService,
        PopupService,
        ImagesService,
        RegexService,
        PushWooshService,
        AddressModificationService,
        HelperService,
        ThemeService,
    ],
    declarations: [
        ReplaceLineBreaks,
        IsHtmlPipe,
        SuccessPopupComponent,
        ChoosePictureModalComponent,
        SetExpiryModalComponent,
        ViewCredentialsComponent,
        CredentialInstructionModalComponent,
        DisapproveReasonModalComponent,
        DatePickerModalComponent,
        NotificationModalComponent,
        NotificationTabComponent,
        CredentialBarComponent,
        WelcomeModalComponent,
        ChooseProfilepictureModalComponent,
        ImageCropperModalComponent
    ],
    exports: [
        ReplaceLineBreaks,
        IsHtmlPipe,
        SuccessPopupComponent,
        ChoosePictureModalComponent,
        SetExpiryModalComponent,
        ViewCredentialsComponent,
        CredentialInstructionModalComponent,
        DisapproveReasonModalComponent,
        CredentialBarComponent,
        WelcomeModalComponent,
        ChooseProfilepictureModalComponent
    ],
    bootstrap: [
        SuccessPopupComponent,
        ChoosePictureModalComponent,
        SetExpiryModalComponent,
        ViewCredentialsComponent,
        ChooseProfilepictureModalComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
