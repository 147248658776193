<div class="main-header">
  <!-- Notifications title -->
  <div class="main-content">
    <h2 class="heading">Upload profile picture</h2>
  </div>

  <div class="main-tabs" (click)="showModal('files')">
    <h2 class="heading">Select from my files</h2>
    <ion-icon class="whole-icon" name="arrow-forward-circle" ></ion-icon>
  </div>

  
  <div class="main-tabs" (click)="showModal('camera')">
    <h2 class="heading">Open camera app</h2>
    <ion-icon class="whole-icon" name="arrow-forward-circle" ></ion-icon>
  </div>

</div>

