<div (scroll)="onScroll($event)" class="notifications-list">
  <div *ngFor="let item of notifications" class="notifications-msg">
    <span class="notification-date">{{ item.createdAt }}</span>
    <h4 class="notification-title">{{ item.title }}</h4>

    <div class="button-inline">
      <p class="notification-decription">{{ item.description }}</p>

      <button *ngIf="item.type === 'approved'" class="detail-icon-btn">
        <ion-icon name="checkmark" size="large" color="secondary"></ion-icon>
      </button>
      <button
        *ngIf="item.type === 'missing'"
        class="detail-btn"
        (click)="showSeeCredentialsModal(item.linkedCredentials)"
      >
        <span class="detail-btn-text">See credentials</span>
      </button>
      <button
        *ngIf="item.type === 'rejected'"
        class="detail-btn"
        (click)="showSeeReasonModal(item.linkedCredentials[0])"
      >
        <span class="detail-btn-text">See reasons</span>
      </button>
      <button *ngIf="item.type === 'compliant'" class="detail-icon-btn">
        <ion-icon name="checkmark" size="large" color="secondary"></ion-icon>
      </button>
      <button
        *ngIf="item.type === 'expired'"
        class="detail-btn"
        (click)="showUploadModal(item.linkedCredentials[0])"
      >
        <span class="detail-btn-text">Upload</span>
      </button>
      <button *ngIf="item.type === 'expiringSoon'" class="detail-btn" (click)="showUploadModal(item.linkedCredentials[0])">
        <span class="detail-btn-text">Upload</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="spinner-container">
    <ion-spinner name="lines"></ion-spinner>
  </div>
</div>
