<div class="login-container">
  <div>
    <ion-card>
      <ion-card-header>
      </ion-card-header>

      <ion-card-content class="card-content">
        <div class="titleImage"></div>

        <h1 class="title">If we find an account associated with this email, we will send you instructions on how to recover your password</h1>
        <form class="login-form " >
          <div class="form-container">

            <div class="input-item action-container" (click)="loginAgain()">
              <ion-button >
                Go to log in
              </ion-button>
            </div>

          </div>
        </form>
      </ion-card-content>
    </ion-card>
    <div class="footer">
      <div class="powered-by">
        Powered by
      </div>
      <img src="../../../assets/images/logo.png" />
    </div>

  </div>
</div>