import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { PushWooshService } from './pushWoosh.service';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private httpService: HttpService,
    private pushWooshService: PushWooshService,
  ) {}

  async logout() {
    try {
      const deviceToken = await this.localStorageService.getDeviceToken();
      if (deviceToken) {
        await this.httpService.httpDeleteRequest(
          `/device-token/${deviceToken}`
        );
        await this.pushWooshService.unregisterDevice();
      }
      await this.localStorageService.logout();
      // Replacing url so user cannot navigate back to previous page
      this.router.navigateByUrl('/login', { replaceUrl: true });
    } catch (error) {
      console.log(error);
    }
  }
}
