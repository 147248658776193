import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { PublicSettings, SettingKeys } from '../interfaces/settings.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
  ) {}

  async setTheme() {
    const url = window.location.host;
    const segments = url.split('.');
    // the subdomain is the company name
    let company = segments[0];
    if (segments.length < 3) {
      company = 'default';
    }

    const publicSettings = await this.fetchPublicSettings(company);
    if (publicSettings) {
      this.localStorageService.setPublicSettings(publicSettings);
      this.setThemeVariables(publicSettings);
    }
  }

  async setThemeViaCache() {
    const publicSettings = await this.localStorageService.getPublicSettings();
    if (publicSettings) {
        this.setThemeVariables(publicSettings);
    }
  }

  setThemeVariables(publicSettings: PublicSettings) {
    const logo = publicSettings.logo;
    const theme = publicSettings.settings.find(
    (setting) => setting.key === SettingKeys.ORGANIZATION_THEME
    );
    const themeColor = theme?.value?.v?.primaryAccent || '#000';
    document.documentElement.style.setProperty('--theme-color', themeColor);
    document.documentElement.style.setProperty(
    '--theme-logo',
    `url(${logo})`
    );
  }

  async fetchPublicSettings(subdomain: string): Promise<PublicSettings> {
    return this.httpService.httpGetRequest<PublicSettings>(
      `/public-settings?subdomain=${subdomain}`
    );
  }
}
