import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  
  constructor() {}

  async openAppBrowserLink(url: string) {
    await Browser.open({ 
        url,
        presentationStyle: 'popover' 
    });
  }

  transformRoleNameToCapitalize(name: string): string {
    const [firstWord, ...restOfWord] = name.split(' ');
    name =
      firstWord.length > 3
        ? name.toLowerCase()
        : [firstWord.toUpperCase(), ...restOfWord.map((row) => row.toLowerCase())].join(' ');
    return name;
  }
}
