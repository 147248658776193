import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy, } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from '../modules/shared/shared.module';

import { AuthGuardService } from './guards/auth.guard';
import { UnAuthGuardService } from './guards/unauth.guard';

import { LoginComponent } from '../modules/authentication/login/login.component';
import { HomeComponent } from '../modules/home/home.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { HeaderWithBackBtnComponent } from './layout/header-with-back-btn/header-with-back-btn.component';
import { UploadCredentialComponent } from '../modules/credentials/upload-credential/upload-credential.component';
import { ViewUploadedCredentialsComponent } from '../modules/credentials/view-uploaded-credentials/view-uploaded-credentials.component'
import { ResetPasswordComponent } from 'src/modules/authentication/reset-password/reset-password.component';
import { AddReferenceFormComponent } from 'src/modules/credentials/add-reference-form/add-reference-form.component';
import { BackgroundCheckComponent } from '../modules/credentials/background-check/background-check.component';
import { BackgroundCheckListItemComponent } from '../modules/background-check-list-item/background-check-list-item.component';
import { RetakeCredentialComponent } from 'src/modules/credentials/retake-credential/retake-credential.component';
import { UploadCredentialModalComponent } from 'src/modules/credentials/upload-credential-modal/upload-credential-modal.component';
import { ForgotPasswordComponent } from 'src/modules/authentication/forgot-password/forgot-password.component';
import { SendInstructionComponent } from 'src/modules/authentication/send-instruction/send-instruction.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    SendInstructionComponent,
    SideMenuComponent,
    HomeComponent,
    HeaderComponent,
    UploadCredentialComponent,
    UploadCredentialModalComponent,
    RetakeCredentialComponent,
    HeaderWithBackBtnComponent,
    ViewUploadedCredentialsComponent,
    AddReferenceFormComponent,
    BackgroundCheckComponent,
    BackgroundCheckListItemComponent,
  ],
  imports: [
    CommonModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    FormsModule, ReactiveFormsModule, SharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuardService,
    UnAuthGuardService,
  ],
  bootstrap: [
    AppComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
