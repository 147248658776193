<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
  <ion-refresher-content></ion-refresher-content>
</ion-refresher>

<div class="home-wrapper">
  <h1>Your Credentials</h1>
  <p>{{ roleName }}<span>{{ moreRoles }}</span> @ {{ location }}</p>
  <div class="separator"></div>

  <div class="loading-container" *ngIf="isLoading">
    <ion-spinner name="lines-sharp-small"></ion-spinner>
  </div>

  <div class="credentials-category">
    <h3>Critical Credentials</h3>
    <ng-container *ngIf="criticalCredentials?.length || isReferenceRequired">
      <ng-container *ngFor="let credential of criticalCredentials">
        <app-credential-bar
          *ngIf="uploadedCredentialStatuses[credential.name] !== 'approved'"
          [credential]="credential"
          [credentialStatus]="uploadedCredentialStatuses[credential.name]"
        ></app-credential-bar>
      </ng-container>
      <ng-container *ngIf="isReferenceRequired">
        <div class="cred-box" (click)="goToReferencesScreen()">
          <div class="details">References</div>
          <div class="actions">
            <!-- <div class="missing">
              <ion-icon
                class="whole-icon"
                name="arrow-forward-circle"
              ></ion-icon>
            </div> -->
            <ng-container [ngSwitch]="referencesSubmited">
              <div class="pending" *ngSwitchCase="true">
                <ion-icon name="time-outline"></ion-icon>
              </div>
              <div class="missing" *ngSwitchDefault>
                <ion-icon
                  class="whole-icon"
                  name="arrow-forward-circle"
                ></ion-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <app-background-check-list-item
      *ngIf="
        backgroundCheckStatus !== 'not_required' &&
        backgroundCheckStatus !== 'approved'
      "
      [backgroundCheckStatus]="backgroundCheckStatus"
    ></app-background-check-list-item>
    <ng-container *ngIf="showNoCriticalCreadentialRequired()">
      <div class="ion-text-center">
        <small>No Credential Required</small>
      </div>
    </ng-container>
  </div>

  <div class="credentials-category">
    <h3>Other Credentials</h3>
    <ng-container
      *ngIf="noNonCriticalCredRequired(); else noNonCriticalCredentialFound"
    >
      <ng-container *ngFor="let credential of nonCriticalCredentials">
        <app-credential-bar
          *ngIf="uploadedCredentialStatuses[credential.name] !== 'approved'"
          [credential]="credential"
          [credentialStatus]="uploadedCredentialStatuses[credential.name]"
        ></app-credential-bar>
      </ng-container>
    </ng-container>
    <ng-template #noNonCriticalCredentialFound>
      <div class="ion-text-center">
        <small>No Credential Required</small>
      </div>
    </ng-template>
  </div>

  <div class="credentials-category ion-margin-bottom">
    <h3>Approved Credentials</h3>
    <ng-container *ngIf="approvedCredentials?.length">
      <div class="cred-box" *ngFor="let credential of approvedCredentials">
        <div class="details">{{ credential }}</div>
        <div class="actions">
          <ion-icon name="checkmark-sharp"></ion-icon>
        </div>
      </div>
    </ng-container>
    <app-background-check-list-item
      *ngIf="backgroundCheckStatus === 'approved'"
      [backgroundCheckStatus]="backgroundCheckStatus"
    ></app-background-check-list-item>
    <ng-container
      *ngIf="!approvedCredentials?.length && backgroundCheckStatus !== 'approved'"
    >
      <div class="ion-text-center">
        <small>No Approved Credentials</small>
      </div>
    </ng-container>
  </div>
</div>

<div class="submit-wrapper">
  <ion-spinner
    name="lines-sharp-small"
    *ngIf="isSubmittingForApproval"
  ></ion-spinner>
  <button
    (click)="submitCredentials()"
    *ngIf="!isSubmittingForApproval"
    [disabled]="disableSubmitingForApproval"
  >
    {{ submitingForApprovalBtnText }}
    <ion-icon class="whole-icon" name="arrow-forward-circle"></ion-icon>
  </button>
</div>
