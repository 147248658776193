<ion-menu contentId="menuContent" swipeGesture='false' side="start" type="overlay" auto-hide="false" #menu>
  <ion-content class="side-menu">
    <div class="flex-container-list">
      <div>
        <ion-list class="no-border">
          <ion-item slot="fixed" class="no-border">
            <div class="flex">
              <div class="icon-cancel">
                <ion-icon class="close-icon" name="close-outline" (click)="closeMenu()"></ion-icon>
              </div>
              <div class="detail">
                <div class="staffer-pic-div">
                  <img class="user-img"
                    (click)="showPictureUploadModal()"
                    [src]="profilePicture || '/assets/images/user-default.png'" />
                </div>
                <div class="user-details">
                  <h1>{{userName}}</h1>
                </div>
              </div>

            </div>
          </ion-item>
          <ion-item *ngIf="showBackBtn" (click)="navBack()" class="active-text no-border">
            <ion-icon name="chevron-back-outline"></ion-icon>
            <span *ngIf="activeMenu?.expand">
              <ion-icon class="active-icon" *ngIf="activeMenu.icon" [name]="activeMenu.icon"></ion-icon>
              {{ activeMenu?.title }}
            </span>
          </ion-item>
        </ion-list>
      </div>

      <div>
        <ion-list [ngClass]="{'no-top-margin' : showBackBtn}">
          <ng-container *ngFor="let page of appPages">
            <ng-container>
              <ion-item class="menu-item" [routerDirection]="'root'" (click)="navigate(page)">

                <ion-icon *ngIf="page.icon" [name]="page.icon"></ion-icon>

                <ion-label class="menu-text" [ngClass]="{'left-spacing' : !page.icon}">
                  <span *ngIf="page.title !== 'Wallet'">
                    {{ page.title }}
                  </span>

                  <span *ngIf="page.title === 'Wallet'">{{ page.title }}</span>
                </ion-label>

                <span *ngIf="page?.expand">
                  <ion-icon name="chevron-forward" class="right-icon"></ion-icon>
                </span>

              </ion-item>
            </ng-container>
          </ng-container>
        </ion-list>
      </div>

      <div class="no-border align-to-end">
        <div class="reserved-rights">
          <h1>All rights reserved - Staffy 2024</h1>
        </div>
      </div>
    </div>
  </ion-content>
</ion-menu>

<div class="ion-page" id="menuContent">
  <app-header></app-header>
  <ion-content class="ion-padding ion-page-scroll">
    <ion-router-outlet></ion-router-outlet>
  </ion-content>
</div>
