export enum SettingKeys {
    ORGANIZATION_THEME = 'ORGANIZATION_THEME'
}

export interface Setting {
    key: SettingKeys,
    value: {v: any}
};

export interface PublicSettings {
    logo: string;
    settings: Setting[]
}
