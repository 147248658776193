<div class="header">
  <div class="content">
    <ion-menu-button auto-hide="false" (click)="openMenu()"></ion-menu-button>
    <div class="name">
      <div class="greeting">
        <p class="greet">Hello,</p>
        <p class="text">{{ userName }}!</p>
      </div>
    </div>
  </div>
  <div *ngIf="showActionRequired | async" class="upcoming">
    <ion-button color="basic" (click)="showModal()">
      <ion-icon name="notifications" class="bell-icon"></ion-icon>
      <span slot="start" class="circle-fill"></span>
      <span class="btn-text">Action required</span>
    </ion-button>
  </div>
  <div class="user-pic-div">
    <img class="user-img" [src]="logo" />
  </div>
</div>

<!-- <span class="btn-text">
  &nbsp;{{ urgentCredentialsCount | async }}&nbsp;Action required
</span> -->
