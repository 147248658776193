import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { DisapproveReasonModalComponent } from '../disapprove-reason-modal/disapprove-reason-modal.component';
import { INotification } from '../../interfaces/notification.interface';
import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { HeaderService } from '../../services/header.service';

type NotificationResponse = {
  data: INotification[];
  totalRecords: number;
};

@Component({
  selector: 'app-notification-tab',
  templateUrl: './notification-tab.component.html',
  styleUrls: ['./notification-tab.component.scss'],
})
export class NotificationTabComponent implements OnInit {
  @Input() type!: string;
  notifications: INotification[] = [];
  isLoading: boolean = false;
  currentPage: number = 0;
  responseLimit = 10;
  isAllNotificationsLoaded = false;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private modalController: ModalController,
    private alertControler: AlertController,
    private headerService: HeaderService,
  ) {}

  async ngOnInit() {
    await this.fetchNotifications();
    if (this.type === 'active') {
      // show action required CTA if notification unread count > 0
      await this.headerService.showActionRequiredCTA();
    }
  }

  async fetchNotifications() {
    if (this.isAllNotificationsLoaded || this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      const data = await this.httpService.httpGetRequest<NotificationResponse>(
        `/notification?status=${this.type}&limit=${this.responseLimit}&offset=${
          this.currentPage * this.responseLimit
        }`
      );
      this.isAllNotificationsLoaded = data.data.length === 0;
      this.notifications = [
        ...this.notifications,
        ...data.data.map((notification) => ({
          ...notification,
          createdAt: moment(notification.createdAt).format(
            'MMM d, YYYY - hh:mm A'
          ),
        })),
      ];
      this.currentPage += 1;
    } catch(error: any) {
      const errorAlert = await this.alertControler.create({header: 'Error!', message: error.message});
      await errorAlert.present();
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @HostListener('scroll', ['$event'])
  async onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      await this.fetchNotifications();
    }
  }

  async showSeeReasonModal(credential: ICredential): Promise<void> {
    await this.modalController.dismiss();
    const reasonModal = await this.modalController.create({
      component: DisapproveReasonModalComponent,
      cssClass: 'autoHeightModal',
      componentProps: {
        credential,
        uploadedCredentials: []
      }
    })
    await reasonModal.present();
  }

  showSeeCredentialsModal(credentials: ICredential[]): void {
    this.modalController.dismiss();
  }

  showUploadModal(credential: ICredential): void {
    this.modalController.dismiss();
    this.router.navigateByUrl('/credentials/upload', {
      state: { credential },
    });
  }
}
