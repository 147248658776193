<div class="disapprove-reason-modal-wrapper">
  <div class="details">
    <div class="img-container">
      <img src="assets/images/notice.png">
    </div>
    <h1>This document has not been approved</h1>
    <p><strong>Reasons for disapproval:</strong></p>
    <p *ngIf="!isUploadedCredsLoading" class="quote">
      &quot;{{ rejectReason || 'No Reason Provided' }}&quot;
    </p>
    <p *ngIf="isUploadedCredsLoading" class="quote">Loading...</p>
  </div>
  <div class="actions">
    <ion-button fill="clear" shape="round" color="dark" (click)="goBack()"
      >Go Back</ion-button
    >
    <ion-button shape="round" color="dark" (click)="reupload()"
      >Re-upload</ion-button
    >
  </div>
</div>
