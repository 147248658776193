import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';


@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent implements OnInit {
  
  @Input() initialDate: Date | undefined;
  selectedDate: string = moment().format('YYYY-MM-DD');
  maxDate: string = moment().format('YYYY-MM-DD');
  minDate?: string;
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    
    if (this.initialDate) {
      this.selectedDate = moment(this.initialDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      console.log("this.selectedDate", this.selectedDate)
    }
  }

  saveDate() {
    console.log(this.selectedDate);
    this.modalController.dismiss({ selectedDate: this.selectedDate }, 'success');
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onChangeDate(e: any){
    this.selectedDate = e.target.value;
  }

}