import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { SetExpiryModalComponent } from '../components/set-expiry-modal/set-expiry-modal.component';
import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { CredentialInstructionModalComponent } from '../components/credential-instruction-modal/credential-instruction-modal.component';
import { DisapproveReasonModalComponent } from '../components/disapprove-reason-modal/disapprove-reason-modal.component';
import { IEmployeeCredential } from 'src/modules/credentials/interfaces/employeeCredential.interface';
import { DatePickerModalComponent } from '../components/date-picker-modal/date-picker-modal.component';
import { UploadCredentialModalComponent } from 'src/modules/credentials/upload-credential-modal/upload-credential-modal.component';
import { WelcomeModalComponent } from '../components/welcome-modal/welcome-modal.component';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  async showModal(
    data: {
      heading: string;
      message: string;
      rejectBtnText?: string;
      btn: string;
      navigateRoute: string | undefined | null;
      imgURL: string | undefined | null;
      list?: string[];
      specificClass?: string;
      externalNavigationUrl?: string;
      disableSubmission?: boolean;
      component?: any;
    },
    cb?: Function,
    cb2?: Function
  ) {
    console.log('SHOW MODAL');
    const myModal = await this.modalController.create({
      component: data.component ? data.component : SuccessPopupComponent,
      backdropDismiss: false,
      cssClass: data.specificClass ? data.specificClass : 'wideModal',
      componentProps: {
        data,
      },
    });

    await myModal.present();
    return await myModal.onDidDismiss().then((dismiss) => {
      if (dismiss.data === 'accept') {
        cb && cb();
      } else if (dismiss.data === 'reject') {
        cb2 && cb2();
      } else cb && cb();
    });
  }

  async showExpiryModal(extractedExpiryDate?: Date) {
    const myModal = await this.modalController.create({
      component: SetExpiryModalComponent,
      backdropDismiss: false,
      cssClass: 'wideModal expiryModal',
      componentProps: {
        extractedExpiryDate,
      },
    });

    await myModal.present();
    return myModal.onDidDismiss();
  }

  async showDatePicker(initialDate?: Date) {
    const myModal = await this.modalController.create({
      component: DatePickerModalComponent,
      backdropDismiss: true,
      cssClass: 'autoWide expiryModal',
      componentProps: {
        initialDate,
      },
    });

    await myModal.present();
    return myModal.onDidDismiss();
  }

  async showCredentialInstructionModal(credential: ICredential): Promise<void> {
    const myModal = await this.modalController.create({
      component: CredentialInstructionModalComponent,
      backdropDismiss: false,
      cssClass: 'autoWide autoHeightModal',
      componentProps: {
        credential,
      },
    });
    await myModal.present();
  }

  async showDisapprovalReasonModal(credential: ICredential): Promise<void> {
    const myModal = await this.modalController.create({
      component: DisapproveReasonModalComponent,
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'autoHeightModal',
      componentProps: {
        credential,
      },
    });
    await myModal.present();
  }

  async showUploadScreenModal() {
    const modal = await this.modalController.create({
      component: UploadCredentialModalComponent,
      backdropDismiss: true,
      cssClass: 'uploadCredentialModal'
    });

    await modal.present();
  }

  async showSubmitForReviewModal(): Promise<void> {
    this.showModal({
      heading: 'Well done!',
      message:
        'We have received your credentials. Our team will review and advise you of any other requirements.',
      btn: 'Ok',
      specificClass: 'submitForReviewModal',
      navigateRoute: undefined,
      imgURL: undefined,
    });
  }

  async showErrorModal(message: string): Promise<void> {
    this.showModal({
      heading: 'Alert',
      message,
      btn: 'Ok',
      specificClass: '',
      navigateRoute: undefined,
      imgURL: undefined,
    });
  }

  async showExpiredCredentialModal(cb: Function): Promise<void> {
    this.showModal(
      {
        heading: 'Your credential is expired',
        message: 'Please upload a new one',
        btn: 'Ok',
        specificClass: 'submitForReviewModal',
        navigateRoute: undefined,
        imgURL: undefined,
      },
      cb
    );
  }

  async showWelcomeModal(user: IUser): Promise<void> {
    const myModal = await this.modalController.create({
      component: WelcomeModalComponent,
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'autoHeightModal welcome-modal',
      componentProps: {
        user,
      },
    });
    await myModal.present();

    await myModal.onDidDismiss();
  }
}
