import { ICredential } from 'src/modules/credentials/interfaces/credential.interface';
import { IOrganization } from '../../organization/interfaces/organization.interface';
import { IRole } from 'src/modules/shared/interfaces/role.interface';

// export interface IRole {
//     description: string;
//     referencesRequired: boolean;
//     criticalCredentials: ICredential[];
//     nonCriticalCredentials: ICredential[];
//     name: string;
// }

export enum BackgroundCheckStatus {
  REQUIRED = 'required',
  NOT_REQUIRED = 'not_required',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  AWAITING_FEEDBACK = 'awaiting_feedback',
}

export type EmployeeIntro = {
  welcome: boolean;
};

export interface IUser {
  id: number;
  name: string;
  email: string;
  organization: IOrganization;
  accessToken: string;
  isTempPasswordReset: boolean;
  roles: IRole[];
  location?: any;
  backgroundCheckStatus: BackgroundCheckStatus;
  submitForApproval?: boolean;
  intro?: EmployeeIntro;
  profilePicture?: string;
}
