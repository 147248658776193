import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { PublicSettings, Setting } from '../interfaces/settings.interface';


@Injectable()
export class LocalStorageService {

    constructor(private storage: Storage) { }

    async init() {
        await this.storage.create();
    }

    setLoggedInUserToken(accessToken: string) {
        return this.storage.set('token', accessToken);
    }

    setDeviceToken(token: string) {
        return this.storage.set('deviceToken', token);
    }

    setPublicSettings(settings: PublicSettings) {
        return this.storage.set('publicSettings', settings);
    }

    setLoggedInUserInfo(user: IUser) {
        return this.storage.set('user', user);
    }

    async removeLoggedInUserInfo() {
        return await this.storage.get('user') || null;
    }

    async getLoggedInUserToken() {
        try {
            return await this.storage.get('token') || null;
        } catch (e) {
            console.log(e);
        }
    }

    async getPublicSettings(): Promise<PublicSettings | null> {
        try {
            return await this.storage.get('publicSettings') || null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getDeviceToken(): Promise<string | null> {
        try {
            return await this.storage.get('deviceToken') || null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async getLoggedInUserInfo() {
        try {
            return await this.storage.get('user') || null;
        } catch (e) {
            console.log(e);
        }
    }

    async logout() {
        try {
            console.log(this.storage);
            await this.storage.clear();
        } catch (e) {
            console.log(e);
        }
    }
}
