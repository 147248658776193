import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, AfterViewChecked, } from '@angular/core';
import { IonDatetime, LoadingController, ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { IonicSlides } from '@ionic/angular';
import { IEmployeeCredential } from 'src/modules/credentials/interfaces/employeeCredential.interface';
import { PopupService } from '../../services/popup.service';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-view-credentials',
  templateUrl: './view-credentials.component.html',
  styleUrls: ['./view-credentials.component.scss'],
})
export class ViewCredentialsComponent implements AfterViewInit, OnChanges {

  @Input()
  uploadedCredentials: IEmployeeCredential[] = [];
  slideConfig: SwiperOptions = {
    // modules: [IonicSlides],
    slidesPerView: 1.3,
    spaceBetween: 30,
    centeredSlides: true,
    effect: 'coverflow',
    coverflowEffect: {
      slideShadows: false,
      depth: 300,
      rotate: 0,
    }
  }
  @ViewChild('swiper')
  swiperRef?: ElementRef<SwiperContainer>;
  currentSlide: number = 0;
  @Output() addCertificate = new EventEmitter();
  @Output() removeCertificate = new EventEmitter();
  showLoading = true;

  constructor(
    private sanitizer: DomSanitizer,
    private popupService: PopupService,
    private platformService: Platform,
    private helperService: HelperService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // Wrapping inside settimeout to make sure it will always run after everything
    setTimeout(() => {
      if (this.swiperRef?.nativeElement) {
        this.swiperRef.nativeElement.swiper.updateSlides();
        this.swiperRef.nativeElement.swiper.slideReset();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initializeSwiperSlider();
  }

  initializeSwiperSlider(): void {
    // Initialize swiper slider withh all config params
    if (this.swiperRef?.nativeElement) {
      const isDesktop = this.platformService.is('desktop');
      if (isDesktop) {
        this.slideConfig = {
          ...this.slideConfig,
          slidesPerView: 1.5,
        }
      }
      // now we need to assign all parameters to Swiper element
      Object.assign(this.swiperRef.nativeElement, this.slideConfig);
      // and now initialize it
      this.swiperRef.nativeElement.initialize();
    }
  }

  addCertificates(action: 'reupload' | 'add' | 'retake', item?: IEmployeeCredential) {
    this.addCertificate.emit({ action, item });
  }

  showReasonOfRejection(item: any) {
    // this.popupService.showModal({
    //   heading: 'This document has not been approved',
    //   message: item.markCertificateAsRejected.reason ? 'Reason for rejection: \n\n' + item.markCertificateAsRejected.reason : '-',
    //   btn: 'Re-upload',
    //   rejectBtnText: 'Ok',
    //   navigateRoute: null,
    //   imgURL: 'assets/images/notice.png'
    // });
  }

  enableDeleteButton(item: IEmployeeCredential) {
    //if certificate is approved or already deleted hide the delete button
    if (item.status === 'approved' || item.status === 'expired'){
      return false;
    }
    return true;
  }

  enableRetakeButton(item: IEmployeeCredential) {
    if (item.status !== 'pending'){
      return false;
    }
    return true;
  }

  enableExpiryTitle(item: IEmployeeCredential) {
    if (item.status !== 'expired'){
      return false;
    }
    return true;
  }

  moveToRetakeScreen(item: IEmployeeCredential) {
    this.addCertificates('retake', item)
  }

  showDeleteCertificatePopup(item: IEmployeeCredential) {
    //show confirmation popup when contractor wants to remove certificate, uploaded accidently
    this.popupService.showModal({
      heading: 'STOP',
      message: 'Are you sure you want to delete this certificate',
      btn: 'Yes',
      rejectBtnText: 'No',
      navigateRoute: null,
      imgURL: 'assets/images/notice.png'
    }, () => {
      console.log("Delete Certificate : ", item);
      this.removeCertificate.emit(item);
    });
  }

  getSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async textLayerRendered(e: CustomEvent) {
    this.showLoading = false;
  }

  onError(error: any) {
    this.showLoading = false;
    // this.popupService.showModal({
    //   heading: 'Error',
    //   message: 'Failed to load document. Please click on link to view certificate file.',
    //   btn: 'Dismiss',
    //   navigateRoute: null,
    //   imgURL: 'assets/images/sorry1.png'
    // });

  }

  onProgress(certificateProgress: any) {
    this.showLoading = true;
  }

  async openLink(url: string) {
    await this.helperService.openAppBrowserLink(url);
  }

  navigateToUpload() {
    // if (!this.userData.isSignupProcessComplete)
    //   this.router.navigateByUrl('onboarding-staffer/certificates');
    // else
    //   this.router.navigateByUrl('talent-dashboard/home');
  }

  isApprovedCredential(item: any) {
    return item.status === 'approved';
  }

  isRejectedCredential(item: any) {
    return item.status === 'rejected';
  }

}
