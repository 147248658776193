import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  urgentCredentialsCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  showActionRequired: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private httpService: HttpService) {}

  async fetchActionRequiredNotificationCount(): Promise<number> {
    try {
      const response = await this.httpService.httpGetRequest<{
        totalRecords: number;
      }>('/notification/count?needAction=true');
      return response.totalRecords;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async showActionRequiredCTA(): Promise<void> {
    const count = await this.fetchActionRequiredNotificationCount();
    this.showActionRequired.next(count > 0);
  }
}
