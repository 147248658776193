<div class="set-expiry-container">
  <div class="ion-text-center child detail">
    <div class="icon-cancel">
      <ion-icon class="close-icon" name="close-outline" (click)="closeModal()"></ion-icon>
    </div>
    <div class="main-txt">
      <p>This is the <strong>expiration</strong> date we detected on your credential:</p>
    </div>
    <div>
      <ion-datetime locale="en-US" [value]="selectedDate" id="date-picker" presentation="date" [preferWheel]="true" [max]="maxDate" (ionChange)="onChangeDate($event)"></ion-datetime>
    </div>
    <hr>
    <div class="buttons-section">
      <p>Please modify it if it is incorrect</p>
      <ion-button color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok btn-round" (click)="saveDate()">
        Save
      </ion-button>
    </div>
  </div>
</div>