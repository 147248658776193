import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpService } from '../../shared/services/http.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { IUser } from '../interfaces/user.interface';
import { AlertController, IonLoading, LoadingController, ModalController } from '@ionic/angular';
import { passwordMatchValidator } from '../validators/passwordValidator';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { ChooseProfilepictureModalComponent } from 'src/modules/shared/components/profile-picture-modal/profile-picture-modal.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  user?: IUser;
  userLoader?: HTMLIonLoadingElement;
  employeeToken?: string;

  public resetPassForm: FormGroup;
  public isSubmitting: boolean = false;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private alertController: AlertController,
    private localStorage: LocalStorageService,
    private popupService: PopupService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private loadingController: LoadingController,
  ) {
    this.resetPassForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      repeatPassword: new FormControl('', [Validators.required]),
    }, {
      validators: passwordMatchValidator('password', 'repeatPassword')
    });
  }

  async processForm() {
    if (this.resetPassForm.valid) {
      console.log(this.resetPassForm.value);
      this.isSubmitting = true;
      try {
        const res: { message: string } = await this.httpService.httpPutRequest(
          '/employees/reset-password',
          this.resetPassForm.value
        );
        this.isSubmitting = false;
        // Updating user state in storage
        const user: IUser = await this.localStorage.getLoggedInUserInfo();
        await this.localStorage.setLoggedInUserInfo({...user, isTempPasswordReset: true});
        // Dont show success message if user is resetting password for the first time
        if (user.isTempPasswordReset) {
          this.showSuccessPopup(res.message);
        }
        this.router.navigateByUrl('/home', { replaceUrl: true });
      } catch (err: any) {
        this.isSubmitting = false;
        this.popupService.showErrorModal(err?.error?.message || 'Something went wrong');
        console.log(err);
      }
    }
  }

  get password() {
    return this.resetPassForm.get('password');
  }

  get repeatPassword() {
    return this.resetPassForm.get('repeatPassword');
  }

  get isPassValid() {
    return (
      this.resetPassForm.controls['password'].value?.length &&
      this.resetPassForm.controls['password'].valid
    );
  }

  get isRepeatPassValid() {
    return (
      this.resetPassForm.controls['repeatPassword'].value?.length &&
      this.resetPassForm.controls['repeatPassword'].valid
    );
  }

  async showSuccessPopup(message: string) {
    this.popupService.showModal({
      heading: 'Success',
      message,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: undefined
    });
  }
  

  async showModal() {
    const myModal = await this.modalController.create({
      component: ChooseProfilepictureModalComponent,
      cssClass: 'chooseProfilePictureModal',
      backdropDismiss: true,
    });
    await myModal.present();
    myModal.onDidDismiss().then((result) => {
      if (result.data && this.user) {
        this.user.profilePicture = result.data
      }
    });
  }

  async ngOnInit() {
    try {
      await this.showLoader();
      this.employeeToken = this.route.snapshot.queryParams['resetToken'] || '';
      if (this.employeeToken) {
        this.user = await this.httpService.httpPostRequest(`/auth/employee/reset-login`, { resetToken: this.employeeToken });
        this.localStorage.setLoggedInUserInfo(this.user);
        this.localStorage.setLoggedInUserToken(this.user.accessToken);
      } else {
        this.user = await this.localStorage.getLoggedInUserInfo();
        if (!this.user) {
          this.router.navigateByUrl('/login');
          throw new Error('No user found');
        }
      }
    } catch (err: any) {
      console.log(err);
      this.popupService.showModal({
        heading: 'Error!',
        message: err.error.message,
        btn: 'Go back to login',
        specificClass: '',
        navigateRoute: undefined,
        imgURL: undefined,
      }, () => {
        this.router.navigateByUrl('/login');
      });
    } finally {
      this.hideLoader();
    }
  }

  async showLoader() {
    this.userLoader = await this.loadingController.create({
      message: 'Please wait',
    });
    return this.userLoader.present();
  }

  hideLoader() {
    this.userLoader?.dismiss();
  }
}
